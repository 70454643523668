import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {Response} from "@core/service/api2/request";
import {OutGet} from "./interface";

@Injectable()
export class InsuranceNumberService {
    constructor(private _request: ApiRequest) {
    }

    /**
     *
     * @returns {Promise<OutGet>}
     */
    get(): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/insurance/number')
            .then((res: Response) => res.json);
    }

}

