import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlaceComponent} from './place.component';


@NgModule({
    declarations: [
        PlaceComponent
    ],
    imports: [
        CommonModule
    ], exports: [
        PlaceComponent
    ]
})
export class SvgPlaceModule {
}
