import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "queryParamProduct",
})
export class QueryParamProductPipe implements PipeTransform {

    transform(id: number, code: string): {} {
        const obj = {};
        if (id && code) {
            obj[`filter_${id}`] = code;
        }
        return obj;
    }

}
