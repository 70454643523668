import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit} from "@angular/core";
import {BasketService} from "@common/core/service/basket";
import {map, Observable} from "rxjs";

@Component({
    selector: "ma-ui-basket-small",
    templateUrl: "./basket-small.component.html",
    styleUrls: ["basket-small.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketSmallComponent implements OnInit {
    @Input() inFixed: boolean = true;

    @HostListener("mouseenter") mouseenter() {
        this.onShow(true);
    }

    @HostListener("mouseleave") mouseleave() {
        this.onShow(false);
    }

    public show: boolean = false;

    public sum$: Observable<number>;
    public count$: Observable<number>;

    constructor(public _basket: BasketService) {}

    ngOnInit() {
        this.sum$ = this._basket.sum$.pipe(
            map((res) => {
                return +res;
            }),
        );
        this.count$ = this._basket.count$;
    }

    onShow(res) {
        this.show = res;
    }
}
