import {inject, Injectable, signal} from "@angular/core";
import {Platform} from "@core/service/platform";

@Injectable({
    providedIn: "root",
})
export class LoadingCommonState {
    private state = signal<boolean>(false);
    public loadingState = this.state.asReadonly();
    private platform = inject(Platform);

    start() {
        if (this.platform.browser) {
            this.state.set(true);
        }
    }

    stop() {
        if (this.platform.browser) {
            this.state.set(false);
        }
    }
}
