<input
        #phone
        type="tel"
        name="phone"
        [style.border-color]="borderColor"
        [(ngModel)]="value"
        mask="900) 000 00 00"
        [patterns]="customPatterns"
        showMaskTyped="true"
        prefix="+7 ("
        shownMaskExpression="___) ___ __ __"
        placeholder="{{placeholder}}"
        [class]="inputClass"
        [tabindex]="tabindex"
        (keyup.enter)="evenEnter()"
        (focus)="eventFocus()"
        (blur)="eventBlur()"
        (click)="eventClick()"
        (ngModelChange)="valueChange($event)"
        [class.not-active]="isNotActiveInput"
        [class.error]="(control.invalid && control.dirty) || (control.invalid && submit) && showError"
        [class.succ]="control.valid && submit"/>
