import {Injectable} from "@angular/core";
import {Platform} from "@core/service/platform";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {filter, take} from "rxjs/operators";
import {interval, Subject} from "rxjs";
import {LogService} from "@core/service/logger";
import {CityService} from "@core/service/city";


@Injectable({ providedIn: 'root' })
export class TalkMeService {

	public openChat$: Subject<boolean> = new Subject<boolean>();
	public closeChat$: Subject<boolean> = new Subject<boolean>();
	public statusChat$: Subject<'open' | 'close'> = new Subject<'open' | 'close'>();

	public fullyLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private _platform: Platform,
		private _logService: LogService,
        private _city: CityService) {
	}

	/**
	 * Старт talk-me
	 *
	 */
	public start(): void {
		if (this._platform.browser) {
			const source = interval(1000);
			source
				.pipe(
					filter(() => window['TalkMe']),
					take(1),
				)
				.subscribe(() => {
					this.eventFullyLoaded();
				});

		}
	}


	public setUserID(deviceToken): void {
		this.fullyLoaded$
			.pipe(filter(res => res === true))
			.pipe(take(1))
			.subscribe(() => {
				TalkMe('setClientInfo', {
					custom: {
						deviceToken: deviceToken,
                        city: this._city.data.name
					},
				});
			});
	}

	/**
	 * Открыть чат
	 */
	public openChat(): void {
		try {
			TalkMe('openSupport');
		} catch (e) {
			this._logService.error('TalkMe', e);
		}

	}

	/**
	 * Событие открытие чата
	 *
	 * @private
	 */

	private eventOpenSupport(): void {
		try {
			TalkMe('setCallback', 'openSupport', () => {
				this.openChat$.next(true);
				this.closeChat$.next(false);
				this.statusChat$.next('open');
			});
		} catch (e) {
			this._logService.error('TalkMe', e);
		}
	}

	/**
	 * Событие закрытие чата
	 *
	 * @private
	 */
	private eventCloseSupport(): void {
		try {
			TalkMe('setCallback', 'closeSupport', () => {
				this.openChat$.next(false);
				this.closeChat$.next(true);
				this.statusChat$.next('close');
			});
		} catch (e) {
			this._logService.error('TalkMe', e);
		}
	}

	/**
	 * Событие загрузки чата
	 *
	 * @private
	 */
	private eventFullyLoaded(): void {
		try {
			TalkMe('setCallback', 'fullyLoaded', () => {
				this.fullyLoaded$.next(true);
				this.eventOpenSupport();
				this.eventCloseSupport();
			});
		} catch (e) {
			this._logService.error('TalkMe', e);
		}
	}

	public setPosition(x: number, y: number) {
		try {
			TalkMe('setPosition', x, y);
		} catch (e) {
			this._logService.error('TalkMe', e);
		}
	}
}
