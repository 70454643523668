import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {CityService} from "@core/service/city";

@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityComponent implements OnInit {
    public count: number = 0;

    constructor(private _city: CityService) {
    }

    ngOnInit(): void {
        this.count = this._city.data.stores_quantity;
        this._city.city$.subscribe(() => {
            this.count = this._city.data.stores_quantity;
        });
    }

}
