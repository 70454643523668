import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PhoneComponent} from "./phone.component";

@NgModule({
    declarations: [
        PhoneComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        PhoneComponent
    ],
})
export class SvgPhoneModule {
}
