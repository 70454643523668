import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../request";
import {InPost, OutPost} from "./interface";
import {Response} from "@core/service/api2/request";
import {EpnService} from "@core/service/epn/epn.service";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class OrdersService {

    constructor(private _epn: EpnService,
                protected _request: ApiRequest,
                private _apiUtilsService: ApiUtilsService) {
    }

    /**
     * Создание заказа
     *
     * @param _obj
     */
    post(_obj?: InPost): Promise<OutPost> {
        const epn = this._epn.get();

        if (epn.click_id) {
            _obj.epn_click_id = epn.click_id;
        }
        if (epn.user_id) {
            _obj.epn_user_id = epn.user_id;
        }

        return this._request
            .create('ma/site/v6/orders', this._apiUtilsService.setDeviceToken(_obj))
            .then((res: Response) => res.json);
    }
}

