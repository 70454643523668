/* Module */
import {NgModule} from "@angular/core";

/* Service */
import {HttpClientModule} from "@angular/common/http";
import {ApiService} from "./api";
import {ApiRequest} from "./request";

import {ApiUtilsService} from "@core/service/api2/utils";
import {PartnersService} from "@core/service/api2/methods/partners";
import {FaqService} from "app/shared/service/api/faq";
import {SymptomsService} from "@core/service/api2/methods/symptoms";
import {ProductsService} from "@shared/service/api/products";
import {ProductsAnaloguesService} from "@core/service/api2/methods/products/analogues";
import {ItemsBotsService} from "@core/service/api2/methods/items/bots";
import {ProductsRandomService} from "@core/service/api2/methods/random";
import {OrdersSubscribeService} from "@core/service/api2/methods/orders/subscribe";
import {StoresSearchStatService} from "@core/service/api2/methods/stores/search/stat";
import {OrdersCashbackService} from "@core/service/api2/methods/orders/cashback";
import {ItemsSubscribeService} from "@core/service/api2/methods/items/subscribe";
import {ActmattersService} from "@core/service/api2/methods/actmatters";
import {AuthTelemetryService} from "@core/service/api2/methods/auth/telemetry";
import {AuthCheckService} from "@core/service/api2/methods/auth/check";
import {InsuranceTypesService} from "@core/service/api2/methods/insurance/types";
import {InsuranceCalcService} from "@core/service/api2/methods/insurance/calc";
import {InsuranceCertService} from "@core/service/api2/methods/insurance/cert";
import {OrdersBasketService} from "@core/service/api2/methods/orders/basket";
import {ItemsService} from "@core/service/api2/methods/items";
import {CartCheckService} from "@core/service/api2/methods/cart/check";
import {OrdersService} from "@core/service/api2/methods/orders";
import {InsuranceNumberService} from "@core/service/api2/methods/insurance/number";
import {ItemsFiltersService} from "@core/service/api2/methods/items/filters";
import {InsuranceHelpNotificationService} from "@core/service/api2/methods/insurance/help-notification";
import {InsuranceFormNotificationService} from "@core/service/api2/methods/insurance/form-notification";
import {ItemsTypeAnalogsService} from "@core/service/api2/methods/items/type-analogs";
import {FeedbackService} from "@core/service/api2/methods/feedback";
import {ArticlesService} from "@core/service/api2/methods/articles";
import {ArticlesDetailService} from "@core/service/api2/methods/articles/detail";
import {ItemsHighDemandService} from "@core/service/api2/methods/items/high-demand";
import {ItemsStockOutService} from "@core/service/api2/methods/items/stock-out";
import {MonitoringService} from "@core/service/api2/methods/monitoring";
import {MaIndexService} from "@core/service/api2/methods/ma-index";
import {MonitoringDetailService} from "@core/service/api2/methods/monitoring/detail";
import {SpecialsDetailService} from "@core/service/api2/methods/specials/detail";
import {SpecialsItemsService} from "@core/service/api2/methods/specials/items";
import {ProductsFeedbackService} from "@core/service/api2/methods/products/feedback";
import {ProductsStoresService} from "@core/service/api2/methods/products/stores";
import {ItemsStoresService} from "@core/service/api2/methods/items/stores";
import {ActmattersDetailService} from "@core/service/api2/methods/actmatters/detail";
import {CitiesService} from "@core/service/api2/methods/cities";
import {CitiesDetailService} from "@core/service/api2/methods/cities/detail";
import {CitiesLocateService} from "@core/service/api2/methods/cities/locate";
import {CitiesSimpleService} from "@core/service/api2/methods/cities/simple";
import {ParametersService} from "@core/service/api2/methods/parameters";
import {ItemsRelatedService} from "@core/service/api2/methods/items/related";
import {GroupsTagsService} from "@core/service/api2/methods/groups/tags";
import {GroupsItemsService} from "@core/service/api2/methods/groups/items";
import {GroupsProductsService} from "app/shared/service/api/groups/products";
import {GroupsService} from "@core/service/api2/methods/groups";
import {TagsDetailService} from "@core/service/api2/methods/tags/detail";
import {TagsSpecialsService} from "@core/service/api2/methods/tags/specials";
import {OrdersRateService} from "@core/service/api2/methods/orders/rate";
import {RatingService} from "@core/service/api2/methods/rating";
import {PharmService} from "@core/service/api2/methods/pharm";
import {GroupsTagsMedkitsService} from "@core/service/api2/methods/groups/tags/medkits";
import {GroupsSpecialsService} from "@core/service/api2/methods/groups/specials";
import {CommentsService} from "@core/service/api2/methods/comments";
import {GroupsElementsService} from "@core/service/api2/methods/groups/elements";
import {ProductsItemsTopService} from "@core/service/api2/methods/products/items/top";
import {CitiesLinkingService} from "@core/service/api2/methods/cities/linking";
import {SendlinkService} from "@core/service/api2/methods/sendlink";

const listMethods = [
    PartnersService,
    FaqService,
    SymptomsService,
    ProductsService,
    ProductsAnaloguesService,
    ItemsBotsService,
    ProductsRandomService,
    OrdersSubscribeService,
    StoresSearchStatService,
    OrdersCashbackService,
    ItemsSubscribeService,
    ActmattersService,
    AuthTelemetryService,
    AuthCheckService,
    InsuranceTypesService,
    InsuranceCalcService,
    InsuranceCertService,
    OrdersBasketService,
    ItemsService,
    CartCheckService,
    OrdersService,
    InsuranceNumberService,
    ItemsFiltersService,
    InsuranceHelpNotificationService,
    InsuranceFormNotificationService,
    ItemsTypeAnalogsService,
    FeedbackService,
    ArticlesService,
    ArticlesDetailService,
    ItemsHighDemandService,
    ItemsStockOutService,
    MonitoringService,
    MaIndexService,
    MonitoringDetailService,
    SpecialsDetailService,
    SpecialsItemsService,
    ProductsFeedbackService,
    ProductsStoresService,
    ItemsStoresService,
    ActmattersDetailService,
    CitiesService,
    CitiesDetailService,
    CitiesLocateService,
    CitiesSimpleService,
    ParametersService,
    ItemsRelatedService,
    GroupsTagsService,
    GroupsItemsService,
    GroupsProductsService,
    GroupsService,
    TagsDetailService,
    TagsSpecialsService,
    OrdersRateService,
    RatingService,
    PharmService,
    GroupsTagsMedkitsService,
    GroupsSpecialsService,
    CommentsService,
    GroupsElementsService,
    ProductsItemsTopService,
    CitiesLinkingService,
    SendlinkService,
];

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [ApiRequest, ApiService, ApiUtilsService, ...listMethods],
    exports: [],
})
export class Api2Module {}
