import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: '.ma-svg-medal',
    templateUrl: './medal.component.svg',
    styles: [':host {display: inherit}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedalComponent {
}
