<div class="header">
    <app-logo class="logo"></app-logo>
    <app-city class="city"></app-city>
    <app-phone class="phone"></app-phone>
    <app-my-order class="my-order"></app-my-order>
    <app-profile class="profile"></app-profile>
</div>
<div class="center">
    <app-search></app-search>
    <ma-ui-basket-small [inFixed]="false" class="basket-small"></ma-ui-basket-small>
</div>

<div class="footer">
    <nav>
        <app-menu class="menu"></app-menu>
    </nav>
    <app-phone class="phone-footer"></app-phone>
</div>
