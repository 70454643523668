import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InPut, OutPut} from "./interface";
import {ApiUtilsService} from "./../../../utils";
import {Response} from "@core/service/api2/request";

@Injectable()
export class AuthTelemetryService {

    constructor(private _request: ApiRequest,
                private _apiUtilsService: ApiUtilsService) {
    }

    /**
     * Список баннеров
     *
     * @param {InPut} obj
     * @returns {Promise<OutPut>}
     */
    put(obj?: InPut): Promise<OutPut> {
        return this._request
            .update('ma/site/v1/auth/telemetry', this._apiUtilsService.setDevice(this._apiUtilsService.setCity(obj)))
            .then((res: Response) => res.json);
    }
}

