import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InGet, OutGet} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class OrdersBasketService {

    constructor(protected _request: ApiRequest) {
    }

    /**
     * Отправка кэшбэка
     *
     * @param _obj
     */
    get(_obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v4/orders/basket', _obj)
            .then((res: Response) => res.json);
    }
}
