<section class="sub-block">
    <div class="wrap">
        <div class="sub-block__img">
            <app-html-image-lazy [alt]="'Установите бесплатное мобильное приложение Megapteka.ru'" [sizeDesktop]="[258, 104]" [srcDesktop]="'/images/mobile/sub_bg.png'"></app-html-image-lazy>

        </div>
        <div class="sub-block-inn">
            <div class="sub-block-inn-links">
                <a rel="noopener" href="https://redirect.appmetrica.yandex.com/serve/890931562466589053?source=blue" target="_blank" class="thisLinkApp thisAS"></a>
                <a rel="noopener" href="https://redirect.appmetrica.yandex.com/serve/818873977370900045?source=blue" target="_blank" class="thisLinkApp thisGP"></a>
                <a rel="noopener" href="https://appgallery.huawei.com/#/app/C101350919?source=blue" target="_blank" class="thisLinkApp thisHu"></a>
                <!--<a rel="noopener" target="_blank" class="thisLinkApp thisMi"></a>-->
            </div>
            <div class="sub-block-inn-text">
                <p class="thisB">Установите бесплатное мобильное приложение Megapteka.ru</p>
                <p>Мы отправим на ваш номер sms со ссылкой на установку</p>
            </div>
            <form class="sub-block-inn-form">
                <div class="sub-block-inn-form-input">
                    <app-input-phone [inputClass]="'formSub'" [control]="form.get('phone')" (enter)="onClick()"></app-input-phone>
                </div>
                <div class="sub-block-inn-form-btn">
                    <div class="for-btn-load " [class.loading]="loader" >
                        <div (click)="onClick()" class="mButton mButton__full c_sky s-s_40">
                            <span class="mButton-text f-s_12">Отправить</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
