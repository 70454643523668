import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InPut, OutPut} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class OrdersRateService {

    constructor(protected _request: ApiRequest) {
    }

    /**
     * Оценка заказа, NPS шкала 0-10
     *
     * @param _obj
     */
    put(_obj?: InPut): Promise<OutPut> {
        return this._request
            .update('ma/site/v6/orders/rate', _obj)
            .then((res: Response) => res.json);
    }

    /**
     * Оценка заказа, шкала 1-5
     *
     * @param _obj
     */
    rateOrder(_obj?: InPut): Promise<OutPut> {
        return this._request
            .create('ma/site/v7/orders/rate', _obj)
            .then((res: Response) => res.json);
    }
}

