import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Injectable({
    providedIn: "root",
})
export class Platform {

    constructor(@Inject(PLATFORM_ID) private platformId: any) {
    }

    get browser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    get server(): boolean {
        return isPlatformServer(this.platformId);
    }
}
