import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "ma-elem-history",
    templateUrl: "./elem-history.component.html",
    styleUrls: ["./elem-history.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElemHistoryComponent {
    @Input() elem: string = "";
}
