import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OkComponent} from './ok.component';


@NgModule({
    declarations: [
        OkComponent
    ],
    imports: [
        CommonModule
    ], exports: [OkComponent]
})
export class SvgOkModule {
}
