import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {CityService} from "@core/service/city";
import {TalkMeService} from "@shared/service/talk-me/talk-me";
import {UserService} from "@core/service/user";
import {skip} from "rxjs/operators";
import {List} from "@shared/module_v2/layout/header/menu-drop/menu-drop.component";

@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    public isRegister: boolean = false;

    public cityCode: string = "";
    public about: { active: boolean } = { active: false };
    public help: { active: boolean } = { active: false };
    public catalog: { active: boolean } = { active: false };
    public isViewPhone: boolean = false;

    public aboutCompanyList: List[] = [
        {
            title: "О компании",
            url: "/about",
        },
        {
            title: "Обратная связь",
            url: "/about/feedback",
        },
        {
            title: "Контакты",
            url: "/about/contacts",
        },
        {
            title: "Наши авторы",
            url: "/about/partners",
        },
        {
            title: "Подключение аптек",
            url: "/pharmacies",
        },
    ];

    public helpList: List[] = [
        {
            title: "Как заказать",
            url: "/help",
        },
        {
            title: "Оплата",
            url: "/help/payment",
        },
        {
            title: "Популярные вопросы",
            url: "/help/faq",
        }
    ];
    constructor(@Inject(DOCUMENT) private document: Document,
                private _city: CityService,
                private _talkMeService: TalkMeService,
                private _cdf: ChangeDetectorRef,
                private _userService: UserService) {
    }

    ngOnInit() {
        this.cityCode = this._city.data.code;
        this._city
            .city$
            .pipe(skip(1))
            .subscribe();

        this._userService.resultCheck
            .pipe(skip(1))
            .subscribe((res) => {
                this.isRegister = res;
            });

    }

    /**
     * Закрытия меню после клика
     *
     * @param menu
     */
    click(obj) {
        obj.active = false;
    }

    /**
     * Открытие меню
     */
    open(obj) {
        obj.active = true;
    }

    /**
     * Закрытие меню
     */
    close(obj) {
        obj.active = false;
    }
}
