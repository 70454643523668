import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../../request";
import {InGet, OutGet} from "./interface";

@Injectable()
export class TagsSpecialsService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Список брендов
     *
     * @param {In} obj
     * @returns Promise<Out>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/tags/specials', obj)
            .then((res: Response) => res.json);
    }
}

