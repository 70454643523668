import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../../../request";
import {InPost, OutPost} from "./interface";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class StoresSearchStatService {

    constructor(private _request: ApiRequest, private _apiUtilsService: ApiUtilsService) {
    }

    /**
     *
     *
     * @param {InPost} _obj
     * @returns Promise<OutPost>
     */
    post(_obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v1/stores/search/stat', this._apiUtilsService.setDevice(this._apiUtilsService.setCity(_obj)))
            .then((res: Response) => res.json);
    }
}

