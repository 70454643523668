import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

export interface List {
    title: string;
    url?: string;
    onClick?(): void;
}

@Component({
    selector: "app-menu-drop",
    templateUrl: "./menu-drop.component.html",
    styleUrls: ["./menu-drop.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDropComponent {
    @Input() list: List[];
}
