import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VkComponent} from './vk.component';


@NgModule({
    declarations: [
        VkComponent
    ],
    imports: [
        CommonModule
    ], exports: [VkComponent]
})
export class SvgVkModule {
}
