import {inject, Injectable} from "@angular/core";
import {Params, Router} from "@angular/router";
import {Redirect} from "./interface";

@Injectable({providedIn: "root"})
export class RedirectBrowserService implements Redirect {
    private router = inject(Router);

    async _301(url: string, params?: Params | null): Promise<boolean> {
        return await this.router.navigate([url], {queryParams: params});
    }

    async _404() {
        return await this.router.navigateByUrl("/404", {skipLocationChange: true});
    }
}
