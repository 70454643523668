import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LogoMComponent} from "./logo-m.component";


@NgModule({
    declarations: [
        LogoMComponent
    ],
    imports: [
        CommonModule
    ], exports: [
        LogoMComponent
    ]
})
export class SvgLogoMModule {
}
