import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    standalone: true,
    selector: "app-licenses",
    templateUrl: "./licenses.component.html",
    styleUrls: ["./licenses.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicensesComponent {

}
