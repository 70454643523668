import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {CityService} from "@core/service/city";

@Pipe({
    name: 'formatHtml'
})

export class FormatHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer, private _cityService: CityService) {}
    transform(_html: string) {
        const city = this._cityService.data;
        if (_html) {
            return this.sanitizer.bypassSecurityTrustHtml(_html.replace(/(#city#)/ig, city.code));
        }
    }
}
