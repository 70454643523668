import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // const {url, method} = request;
        // if (url.endsWith("ma/site/v4/items/favorites/recommendations/") && method === "GET") {
        //     const obj = {
        //         "id": 121390,
        //         "code": "flukonazol-kaps-150-121390",
        //         "group_code": "akusherstvo-ginekologiya-43",
        //         "name": "ФЛУКОНАЗОЛ капсулы 150 мг 1 шт.",
        //         "brand_name": "Производство медикаментов ООО",
        //         "images": [],
        //         "is_norecipe": null,
        //         "is_cashback": null,
        //         "packaging_text": null,
        //         "dosage_text": null,
        //         "price": 25,
        //         "stores_quantity": 1,
        //         "is_available": true,
        //         "availables": 1,
        //         "discount_percent": null,
        //         "min_price_avail": 1,
        //         "max_price": 25,
        //         "store_brands": [],
        //         "availability_index": {
        //             "text": "ИН 34",
        //             "color": "#F16232",
        //             "direction": 0,
        //         },
        //         "availables_from_stock": 0,
        //         "is_available_from_stock": false,
        //         "stores_quantity_from_stock": 0,
        //         "add_cart_warning": null,
        //     };
        //
        //     return of(new HttpResponse({
        //         status: 200, body: {
        //             list: Array(10).fill(obj),
        //         },
        //     })).pipe(delay(500));
        // }

        return next.handle(request);
    }
}
