import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../request";
import {InPost, OutPost} from "./interface";

@Injectable()
export class RatingService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Метод для отправки оценки
     *
     * @param {InPost} obj
     * @returns Promise<OutPost>
     */
    post(obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v1/rating', obj)
            .then((res: Response) => res.json);
    }
}

