import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-input-email',
    templateUrl: 'email.component.html'
})
export class EmailComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() control: UntypedFormControl;
    @Input() type: string = 'grey';
    @Input() placeholder: string = 'Ваш e-mail';
    @Input() prefix: string = '';
    @Input() showText: boolean = true;
    @Input() focus: boolean = false;
    @Input() tabindex: string = '';
    @Input() inputClass: string = 'form6';

    @Output() enter = new EventEmitter<boolean>();
    @Output() blur = new EventEmitter<boolean>();

    @ViewChild('email') elemInput: ElementRef;

    public name: string = 'email';
    public isShow: boolean = true;
    public submit: boolean = false;

    ngOnInit() {
        const emailRegexp = new RegExp(
            [
                '^',
                '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*',
                '|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]',
                '|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")',
                '@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
                '|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}',
                '(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:',
                '(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]',
                '|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)',
                '\\])',
                '$'
            ].join(''), 'i'
        );

        this.control.setValidators([Validators.required, Validators.pattern(emailRegexp)]);

        const controlSubmit = this.control.root.get('submit');
        if (controlSubmit) {
            controlSubmit.valueChanges.subscribe((res) => {
                this.submit = res;
            });
        }

        if (this.prefix.length > 0) {
            this.name = `${this.prefix}_${this.name}`;
        }

        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
    }

    evenEnter() {
        // this.enter.emit(true);
    }

    evenBlur() {
        this.blur.emit(true);
    }
}
