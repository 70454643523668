import {ChangeDetectionStrategy, Component} from "@angular/core";
import {HtmlAModule} from "@shared/module_v2/html/a/a.module";

@Component({
    standalone: true,
    selector: "app-mobile-app",
    templateUrl: "./mobile-app.component.html",
    styleUrls: ["./mobile-app.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HtmlAModule,
    ],
})
export class MobileAppComponent {

}
