import {Pipe, PipeTransform} from "@angular/core";
import {Group} from "@component/layout/menu-catalog/list/list";

@Pipe({
    name: "columnCount",
    standalone: true,
})
export class ColumnCountPipe implements PipeTransform {

    transform(arr: Group["children"]): number {
        if (arr.length > 30) {
            return 3;
        }
        if (arr.length > 15 && arr.length < 31) {
            return 2;
        }

        return 1;
    }

}
