import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneCall'
})
export class PhoneCallPipe implements PipeTransform {

    transform(_phone?: string) {
        if (_phone) {
            const phone = _phone.trim();
            if (phone) {
                if (/^7{1,1}/i.test(phone)) {
                    return '+' + phone.replace(/\s+|\(|\)|-/gi, '');
                } else {
                    return phone.replace(/\s+|\(|\)|-/gi, '');
                }
            }
        }

        return '';
    }
}
