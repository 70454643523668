import {Injectable} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {skip, take} from "rxjs/operators";
import {Cookie} from "@core/service/cookie";

@Injectable({
    providedIn: "root",
})
export class UtmService {
    private nameStorage = "ma_utm";

    public queryParams: {
        utm_source: string;
        utm_medium: string;
        utm_campaign: string;
        utm_term: string;
        utm_content: string;
    } = {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
    };

    constructor(private _activatedRoute: ActivatedRoute, private _cookie: Cookie) {
    }

    public init() {
        this._activatedRoute.queryParams
            .pipe(skip(1), take(1))
            .subscribe((resQueryParams) => {
                let triggerSave = false;
                for (const [key] of Object.entries(this.queryParams)) {
                    if (resQueryParams[key]) {
                        this.queryParams[key] = resQueryParams[key];
                        triggerSave = true;
                    }
                }
                if (triggerSave) {
                    this.setStorage();
                }
            });
    }

    /**
     * Получить список меток
     */
    public get() {
        const obj = this.getStorage();

        if (!obj) {
            return null;
        }
        const tmp = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value) {
                tmp[key] = value;
            }
        }
        if (Object.keys(tmp).length === 0) {
            return null;
        }

        return tmp;
    }

    /**
     * Удаляение всех меток
     */
    public clear() {
        for (const [key] of Object.entries(this.queryParams)) {
            this.queryParams[key] = "";
        }
        this._cookie.setItem(this.nameStorage, null);
    }

    /**
     * Сохранение в локальном хранилеще
     */
    public setStorage() {
        const val = JSON.stringify(this.queryParams);
        this._cookie.setItem(this.nameStorage, val);
    }

    public getStorage() {
        let obj = {};
        try {
            const val = this._cookie.getItem(this.nameStorage);
            if (val) {
                obj = JSON.parse(val);
            }
        } catch (e) {
            console.error(e);
        }
        return obj;
    }
}
