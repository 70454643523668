import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: 'app-svg-arrow-down, .ma-svg-arrow-down',
    templateUrl: './arrow-down.component.svg',
    styles: [':host {display: inherit}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowDownComponent {
}
