import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ArrowDownComponent} from "./arrow-down.component";


@NgModule({
    declarations: [
        ArrowDownComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ArrowDownComponent
    ]
})
export class SvgArrowDownModule {
}
