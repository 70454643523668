import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {Response} from "@core/service/api2/request";
import {InPost, OutPost} from "./interface";

@Injectable()
export class InsuranceHelpNotificationService {
    constructor(private _request: ApiRequest) {
    }

    /**
     *
     * @returns {Promise<OutPost>}
     */
    post(obj: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v1/insurance/help-notification', obj)
            .then((res: Response) => res.json);
    }

}

