<input
        #email
        type="text"
        placeholder="{{placeholder}}"
        class="{{inputClass}}"
        [tabindex]="tabindex"
        [formControl]="control"
        [class.error]="(control.invalid && control.dirty && submit) || (control.pristine && control.invalid && submit)"
        (keyup.enter)="evenEnter()"
        (blur)="evenBlur()"
/>