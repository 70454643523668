import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../request";
import {InGet, OutGet} from "./interface";

@Injectable()
export class SymptomsService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Список брендов
     *
     * @param {In} obj
     * @returns Promise<Out>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/symptoms/detail', obj)
            .then((res: Response) => res.json);
    }
}

