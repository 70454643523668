import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InGet, OutGet} from "./interface";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class ProductsFeedbackService {

    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     * Список
     *
     * @param {InGet} obj
     * @returns Promise<OutGet>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v2/products/feedback', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }
}


