import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "any",
})
export class TextService {
    /**
     * Трансформация окончания
     *
     * @param _number number
     * @param _words string[]
     *
     * @return string
     */
    transform(_number: number, _words: string[]): string {
        let result = "";
        const number: number = _number % 100;
        if (number >= 11 && number <= 19) {
            result = _words[2];
        } else {
            const i = number % 10;

            switch (i) {
                case (1):
                    result = _words[0];
                    break;
                case (2):
                case (3):
                case (4):
                    result = _words[1];
                    break;
                default:
                    result = _words[2];
                    break;
            }
        }

        return result;
    }

    /**
     * Первая заглавная буква
     *
     * @param str
     *
     * @return string
     */
    setFirstLetterCapital(str: string): string {
        if (!str) {
            return "";
        }
        return str[0].toUpperCase() + str.slice(1);
    }
}


