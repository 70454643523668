<div class="search-header__container">
    <svg-icon
        *ngIf="isFloating === false"
        class="search-header__icon search-header__unicorn"
        [src]="'icons/svg/search_unicorn.svg'"></svg-icon>
    <svg-icon *ngIf="isFloating === true" class="search-header__icon search-header__search" [src]="'icons/svg/search.svg'"></svg-icon>
    <input
        class="search-header__input shadow-card"
        type="text"
        #input
        [ngModel]="query$ | async"
        (ngModelChange)="onChangedQuery($event)"
        (keydown.enter)="onClickQuery(input.value)"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        placeholder="{{ (isPlaceholderMobile$ | async) ? 'Искать в аптеках' : 'Название лекарства или действующего вещества' }}" />
    <svg-icon *ngIf="query$ | async" (click)="onClickDelete()" class="search-header__close" [src]="'icons/svg/close.svg'"></svg-icon>
    <ma-ui-request-search-header-hints
        *ngIf="isViewHints"
        [query]="query$ | async"
        (onSearchValue)="onSearchValue($event)"
        class="search-header__hints shadow-card"></ma-ui-request-search-header-hints>
</div>
<button *ngIf="isFloating === false" type="button" (click)="onClickQuery(input.value)" maButtonGeneral
        class="search-header__button lg uppercase">НАЙТИ
</button>
