<ng-template [ngIf]="!link && routerLink">
    <a [routerLink]="routerLink"
       [attr.rel]="rel"
       [attr.title]="title"
       [attr.target]="target">
        <ng-container *ngTemplateOutlet="cont"></ng-container>
    </a>
</ng-template>
<ng-template [ngIf]="link && !routerLink">
    <a href="{{link}}"
       [attr.rel]="rel"
       [attr.title]="title"
       [attr.target]="target">
        <ng-container *ngTemplateOutlet="cont"></ng-container>
    </a>
</ng-template>
<ng-template #cont>
    <ng-content></ng-content>
</ng-template>
