import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment-mini-ts";
import {Config} from "../../core/service/config";

@Pipe({
    name: 'timeSite'
})
export class TimeSitePipe implements PipeTransform {
    transform(_time?: number): string {
        const configSite = new Config().get('site');
        return <string>moment.unix(_time).utc().format(configSite.date.time);
    }
}
