import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ArrowUpComponent} from "./arrow-up.component";


@NgModule({
    declarations: [
        ArrowUpComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ArrowUpComponent
    ]
})
export class SvgArrowUpModule {
}
