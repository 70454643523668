import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

interface Comp<T> extends Function {
    new(...args: any[]): T;
}

interface LayoutHeaderFloatingRef<T> {
    comp: Comp<T>,
    data: {}
}

@Injectable({
    providedIn: 'root'
})
export class LayoutHeaderFloatingService {
    public layoutHeaderFloatingRef$: BehaviorSubject<LayoutHeaderFloatingRef<any>[]> = new BehaviorSubject([]);
    getRef(): LayoutHeaderFloatingRef<any>[] {
        return this.layoutHeaderFloatingRef$.getValue();
    }

    setRef<T>(comp: LayoutHeaderFloatingRef<T> | LayoutHeaderFloatingRef<T>[]): void {
        const list = [];
        if (comp instanceof Array) {
            comp.map((v) => {
                list.push(v);
            });
        } else {
            list.push(comp);
        }
        this.layoutHeaderFloatingRef$.next(list);
    }

    delRef<T>(comp: Comp<T>) {
        const list = this.getRef();
        const listNew: LayoutHeaderFloatingRef<any>[] = list.reduce((a: any[], v) => {
            if (comp !== v.comp) {
                a.push(v);
            }
            return a;
        }, []);
        this.setRef(listNew);
    }

    eventRef(): BehaviorSubject<LayoutHeaderFloatingRef<any>[]> {
        return this.layoutHeaderFloatingRef$;
    }
}
