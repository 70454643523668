import {inject, Pipe, PipeTransform} from "@angular/core";
import {formatCurrency} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: "formatPrice",
    standalone: true,
})

export class FormatPricePipe implements PipeTransform {
    private sanitizer = inject(DomSanitizer);

    transform(_price: number = 0, digits: number = 2, currency: string = "₽") {
        const price = +_price;
        const digitsInfo = `1.${digits}-${digits}`;

        if (price === undefined || Number.isNaN(price)) {
            return "";
        }
        if (typeof price !== "number" && !isFinite(price)) {
            return "";
        }

        return formatCurrency(_price, "RU", currency, "RUB", digitsInfo)
            .replace(/\s+/g, "\u202F")
            .replace(/(,00)/, "");
    }
}
