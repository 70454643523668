import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SearchComponent} from "./search.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SvgMagnifierModule} from "@shared/module_v2/svg/magnifier/magnifier.module";
import {SvgDotsModule} from "@shared/module_v2/svg/dots/dots.module";
import {AngularSvgIconModule} from "angular-svg-icon";


@NgModule({
    declarations: [
        SearchComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SvgMagnifierModule,
        SvgDotsModule,
        AngularSvgIconModule,
    ],
    exports: [
        SearchComponent
    ],
})
export class InputSearchModule {
}
