import {Inject, Injectable} from "@angular/core";
import {ORIGIN_HEADERS} from "@shared/injection-token/tokens";

@Injectable()

export class UserAgentService {

    constructor(@Inject(ORIGIN_HEADERS) public _baseHeaders: any) {
    }


    get() {
        let userAgent = '';
        if (this._baseHeaders) {
            if (this._baseHeaders['user-agent']) {
                userAgent = this._baseHeaders['user-agent'] || "";
            }
            if (!userAgent) {
                userAgent = this._baseHeaders['userAgent'] || "";
            }
        } else {
            userAgent = window.navigator.userAgent || "";
        }


        return userAgent;
    }
}
