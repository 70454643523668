import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InPost, OutPost} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class AuthCheckService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Проверка авторизации
     *
     * @param {InPost} obj
     * @returns {Promise<OutPost>}
     */
    post(obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v1/auth/check', obj)
            .then((res: Response) => res.json);
    }
}

