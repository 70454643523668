import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InSet, OutSet} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class OrdersSubscribeService {

    constructor(protected _request: ApiRequest) {
    }

    /**
     * Подписаться о снижении цены
     *
     * @param _obj
     */
    put(_obj?: InSet): Promise<OutSet> {
        return this._request
            .update('ma/site/v3/orders/subscribe', _obj)
            .then((res: Response) => res.json);
    }
}

