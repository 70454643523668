import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {OrdersBasketInGet, OrdersBasketOutGet} from "./interface";

@Injectable({
  providedIn: 'root'
})
export class OrdersBasketService {

    constructor(private _httpClient: HttpClient) {
    }

    /**
     *
     *
     * @returns {Observable<OrdersBasketOutGet>}
     */
    get(_obj: OrdersBasketInGet): Observable<OrdersBasketOutGet> {
        return this._httpClient.get<OrdersBasketOutGet>('ma/site/v4/orders/basket', {params: {data: <string>JSON.stringify(_obj)}});
    }
}

