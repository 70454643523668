import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {UserService} from "@core/service/user";
import {Observable} from "rxjs";
import {List} from "@shared/module_v2/layout/header/menu-drop/menu-drop.component";
import {BackUrlService} from "@common/core/service/back-url";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
    public isAuth$: Observable<boolean>;
    public isMenu: boolean = false;

    public menuList: List[] = [
        {
            title: "Мой профиль",
            url: "/lk/profile",
        },
        {
            title: "Мои заказы",
            url: "/lk/orders",
        },
        {
            title: "Избранное",
            url: "/lk/favorites",
        },
        {
            title: "Мои адреса",
            url: "/lk/addresses",
        },
        {
            title: "Выйти",
            onClick: () => {
                this._userService.logout().subscribe(() => {
                    this._backUrl.redirect();
                });
            },
        },
    ];

    constructor(
        private _userService: UserService,
        private _backUrl: BackUrlService,
    ) {}

    ngOnInit(): void {
        this.isAuth$ = this._userService.resultCheck;
    }
}
