import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LayoutHeaderComponent} from "./header.component";
import {SvgLogoMaModule} from "@shared/module_v2/svg/logo-ma/logo-ma.module";
import {RouterModule} from "@angular/router";
import {ButtonCityLinkModule} from "@shared/module_v2/button/city-link/city-link.module";
import {SvgBoxModule} from "@shared/module_v2/svg/box/box.module";
import {SvgMedalModule} from "@shared/module_v2/svg/medal/medal.module";
import {LogoComponent} from "./logo/logo.component";
import {CityComponent} from "./city/city.component";
import {SharedPipeModule} from "@shared/shared.pipe";
import {SvgArrowDownModule} from "@shared/module_v2/svg/arrow-down/arrow-down.module";
import {SvgPhoneModule} from "@shared/module_v2/svg/phone/phone.module";
import {PhoneComponent} from "./phone/phone.component";
import {UiBasketSmallModule} from "@shared/module_v2/ui/basket-small/basket-small.module";
import {MenuComponent} from "./menu/menu.component";
import {MyOrderComponent} from "./my-order/my-order.component";
import {ProfileComponent} from "./profile/profile.component";
import {SvgPersonalAccountModule} from "@shared/module_v2/svg/personal-account/personal-account.module";
import {SearchComponent} from "./search/search.component";
import {InputSearchModule} from "@shared/module_v2/input/search/search.module";
import {MenuDropComponent} from "./menu-drop/menu-drop.component";
import {SharedDirectiveModule} from "@shared/shared.directive";
import {LayoutMenuCatalogModule} from "@shared/module_v2/layout/menu-catalog/menu-catalog.module";
import {UiPopupYourCityModule} from "@shared/module_v2/ui/popup-your-city/popup-your-city.module";
import {UiSearchHeaderModule} from "@shared/module_v2/ui/search-header/search-header.module";
import {AngularSvgIconModule} from "angular-svg-icon";


@NgModule({
    declarations: [
        LayoutHeaderComponent,
        LogoComponent,
        CityComponent,
        PhoneComponent,
        MenuComponent,
        MyOrderComponent,
        ProfileComponent,
        SearchComponent,
        MenuDropComponent
    ],
    imports: [
        CommonModule,
        SvgLogoMaModule,
        RouterModule,
        ButtonCityLinkModule,
        SvgBoxModule,
        SvgMedalModule,
        SharedPipeModule,
        SvgArrowDownModule,
        SvgPhoneModule,
        UiBasketSmallModule,
        SvgPersonalAccountModule,
        InputSearchModule,
        SharedDirectiveModule,
        LayoutMenuCatalogModule,
        UiPopupYourCityModule,
        UiSearchHeaderModule,
        AngularSvgIconModule,
    ],
    exports: [
        LayoutHeaderComponent,
    ],
})
export class LayoutHeaderModule {
}
