import {inject, Injectable} from "@angular/core";
import {MetaLinkService} from "./link.service";
import {UrlSerializer, UrlTree} from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class MetaCanonicalService {
    private metaLinkService = inject(MetaLinkService);
    private urlSerializer = inject(UrlSerializer);

    public set(url: string = "") {
        if (url === "") {
            return;
        }
        const urlTree: UrlTree = this.urlSerializer.parse(url);
        this.metaLinkService.set({rel: "canonical", href: `https://megapteka.ru${this.urlSerializer.serialize(urlTree)}`});
    }

    public remove() {
        this.metaLinkService.remove({rel: "canonical"});
    }

    public get() {
        return this.metaLinkService.get({rel: "canonical"});
    }
}
