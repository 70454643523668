import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FloatingBlockBottomComponent} from "@shared/module_v2/ui/floating-block-bottom/floating-block-bottom.component";
import {ButtonTalkMeModule} from "@shared/module_v2/button/talk-me/talk-me.module";
import {FooterMobileComponent} from "@shared/module_v2/layout/footer-mobile/footer-mobile.component";

@NgModule({
    declarations: [FloatingBlockBottomComponent],
    imports: [
        CommonModule,
        ButtonTalkMeModule,
        FooterMobileComponent,
    ],
    exports: [
        FloatingBlockBottomComponent,
    ],
    providers: [

    ]
})
export class UiFloatingBlockBottomModule {
}
