import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MagnifierComponent} from './magnifier.component';


@NgModule({
    declarations: [
        MagnifierComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MagnifierComponent
    ]
})
export class SvgMagnifierModule {
}
