import {PipeTransform, Pipe} from '@angular/core';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
    transform(item: any, replace: string, replacement: string): any {
        if (item == null) {
            return '';
        } else {
            item = (item).toString().replace(replace, replacement);
            return item;
        }
    }
}
