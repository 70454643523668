import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";
import {OutGet} from "@core/service/api2/methods/insurance/types/interface";

@Injectable()
export class InsuranceTypesService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     *
     * @returns {Promise<OutGet>}
     */
    get(): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/insurance/types')
            .then((res: Response) => res.json);
    }

}

