import {Injectable} from "@angular/core";
import {Platform} from "@core/service/platform";
import {MobileService} from "@core/service/mobile";
import {BreakpointObserver} from "@angular/cdk/layout";
import {EMPTY} from "rxjs/internal/observable/empty";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

export interface IResize {
    mobile: boolean;
    table: boolean;
    desktop: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class Resize2Service {
    public resize$: BehaviorSubject<IResize> = new BehaviorSubject({mobile: true, table: false, desktop: false});

    private breakpointParams: string[] = [
        '(min-width: 320px) and (max-width: 768px)',
        '(min-width: 768px) and (max-width: 1000px)',
        '(min-width: 1000px) and (max-width: 1240px)',
    ];

    constructor(private _platform: Platform,
                private _mobileService: MobileService,
                private _breakpointObserver: BreakpointObserver) {
    }


    public load() {
        if (this._platform.server) {
            if (this._mobileService.isTablet()) {
                this.resize$.next({mobile: false, table: true, desktop: false});
            } else if (this._mobileService.isMobile()) {
                this.resize$.next({mobile: true, table: false, desktop: false});
            } else {
                this.resize$.next({mobile: false, table: false, desktop: true});
            }
        } else {
            this._breakpointObserver
                .observe(this.breakpointParams).subscribe(result => {
                if (result.breakpoints['(min-width: 320px) and (max-width: 768px)'] === true) {
                    this.resize$.next({mobile: true, table: false, desktop: false});
                } else if (result.breakpoints['(min-width: 768px) and (max-width: 1000px)'] === true) {
                    this.resize$.next({mobile: false, table: true, desktop: false});
                } else {
                    this.resize$.next({mobile: false, table: false, desktop: true});
                }
            });
        }

        return EMPTY;
    }

}
