import {Injectable} from "@angular/core";
import {SendlinkInPost} from "./interface";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "any",
})
export class SendlinkService {

    constructor(private _httpClient: HttpClient, private _city: CityService) {
    }

    /**
     * Cсылка на установку мобилки
     *
     * @param {SendlinkInPost} _obj
     * @returns Observable<void>
     */
    post(_obj?: SendlinkInPost): Observable<void> {
        return this._httpClient.post<void>("ma/site/v1/sendlink", _obj);
    }
}

