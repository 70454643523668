import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {Response} from "@core/service/api2/request";
import {InGet, OutGet} from "@core/service/api2/methods/insurance/types/interface";

@Injectable()
export class InsuranceCalcService {
    constructor(private _request: ApiRequest) {
    }

    /**
     *
     * @returns {Promise<OutGet>}
     */
    get(obj: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/insurance/calc', obj)
            .then((res: Response) => res.json);
    }

}

