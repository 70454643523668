import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ClientsInPut, ClientsOutGet, ClientsOutPut} from "./interface";

@Injectable({providedIn: "any"})
export class ClientsService {
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Данные пользователя
     *
     * @returns {Observable<ClientsOutGet>}
     */
    get(): Observable<ClientsOutGet> {
        return this._httpClient.get<ClientsOutGet>("ma/site/v2/clients");
    }

    /**
     * Обновление данных пользователя
     *
     * @returns {Observable<void>}
     */
    put(_obj: ClientsInPut): Observable<ClientsOutPut> {
        return this._httpClient.put<ClientsOutPut>("ma/site/v1/clients", _obj);
    }
}
