import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from "@angular/core";
import {AngularSvgIconModule} from "angular-svg-icon";
import {NgIf} from "@angular/common";

@Component({
    selector: "ma-ui-sticker-color",
    standalone: true,
    imports: [
        AngularSvgIconModule,
        NgIf,
    ],
    templateUrl: "./sticker-color.component.html",
    styleUrls: ["./sticker-color.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiStickerColorComponent implements OnInit{
    @Input() set color(val: string) {
        this.backgroundColor = val;
    }
    @Input() image: string = "";
    @Input() text: string = "";

    @ViewChild('textRef', { static: false }) textRef: ElementRef;

    @HostBinding("style.background-color") backgroundColor: string = "";
    @HostBinding("title") title: string = "";


    ngOnInit() {
        this.title = this.text;
    }
}
