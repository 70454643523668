<section class="wrapper">
    <div class="footer-alphabet">
        <h2 *ngIf="isTitle">Список товаров по алфавиту</h2>
        <div class="footer-alphabet__flex">
            <a
                *ngFor="let ab of abc"
                title="{{ab}}"
                routerLink="/products/alphabet/{{ab}}"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}">
                {{ ab.toUpperCase() }}
            </a>
        </div>
    </div>
</section>
