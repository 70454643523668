import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PersonalAccountComponent} from "./personal-account.component";


@NgModule({
    declarations: [
        PersonalAccountComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PersonalAccountComponent
    ]
})
export class SvgPersonalAccountModule {
}
