import {inject, Injectable} from "@angular/core";
import {GroupsMenuOutGet, GroupsMenuService} from "@shared/service/api/groups/menu";
import {filter, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class MenuCatalogService {
    private groupsMenuService = inject(GroupsMenuService);

    getGroups(): Observable<GroupsMenuOutGet["list"]> {
        return this.groupsMenuService.get()
            .pipe(
                filter(res => !!res?.list),
                map(res => res.list),
            );
    }
}
