<div class="popup-your-city" [class.down]="isActive" *ngIf="isActive">
    <div class="popup-your-city-inn">
        <div class="popup-your-city-text">Ваш город <b>{{city.name}}</b>?</div>
        <div class="popup-your-city-active">
            <div class="popup-your-city-active-btn">
                <div (click)="onClickYes()" class="mButton mButton__full c_red s-s_30"><span class="mButton-text f-s_11">Да</span></div></div>
            <div class="popup-your-city-active-other"><span (click)="onClickNo()">Выбрать другой город</span></div>
        </div>
    </div>
</div>
