import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../../request";
import {InGet, OutGet} from "./interface";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class TagsDetailService {

    constructor(private _request: ApiRequest,  private _apiUtilsService: ApiUtilsService) {
    }

    /**
     * Список брендов
     *
     * @param {In} obj
     * @returns Promise<Out>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/tags/detail', this._apiUtilsService.setCity(obj))
            .then((res: Response) => res.json);
    }
}

