<ng-template [ngIf]="data().status === 'empty'">
    <ng-container *ngTemplateOutlet="tag"></ng-container>
    <ng-container *ngTemplateOutlet="history"></ng-container>
</ng-template>
<ng-template [ngIf]="data().status === 'fount'">
    <ng-container *ngTemplateOutlet="list"></ng-container>
</ng-template>
<ng-template [ngIf]="data().status === 'not_fount'">
    <ng-container *ngTemplateOutlet="tag"></ng-container>
</ng-template>
<ng-template #tag>
    <div class="tags">
        <div class="tags__title">
            <b>Поможем подобрать препараты</b>
        </div>
        <div class="tags__list">
            <a (click)="onClickTagColor(tag)" routerLink="/search" [queryParams]="{q: tag.query}"
               *ngFor="let tag of data().queries; let index = index">
                <ma-ui-sticker-color class="sticker-color_text"
                                     [color]="index | colorRandom"
                                     [text]="tag.query">
                </ma-ui-sticker-color>
            </a>
        </div>
    </div>
</ng-template>
<ng-template #list>
    <a
        class="elem"
        (click)="onClickProduct(elem)"
        *ngFor="let elem of data()?.products | slice: 0:3; trackBy: trackByFn"
        routerLink="/{{ cityCode }}/{{ elem.code }}/{{ elem.filter_code }}">
        <ma-elem-product [name]="elem.name" [img]="elem.images" [groups]="elem.groups_string" />
    </a>

    <div class="elem title" *ngIf="data()?.groups?.length > 0 || data()?.tags.length > 0">
        <b>Категории</b>
    </div>
    <a
        class="elem"
        *ngFor="let tag of data()?.tags | slice: 0:3; trackBy: trackByFn"
        (click)="onClickTag(tag)"
        [routerLink]="['/', 'tags', tag.group_code, tag.code]">
        <ma-elem-item [name]="tag.name" />
    </a>
    <a
        class="elem"
        *ngFor="let group of data()?.groups | slice: 0:3; trackBy: trackByFn"
        (click)="onClickGroup(group)"
        [routerLink]="['/', cityCode, 'catalog', group.code]">
        <ma-elem-item [name]="group.name" />
    </a>
</ng-template>
<ng-template #history>
    <!--    <ng-template [ngIf]="(_query$ | async).length === 0">-->
    <a class="elem" (click)="onClickHistory(elem)" *ngFor="let elem of data().history()" routerLink="/search" [queryParams]="{q: elem}">
        <ma-elem-history [elem]="elem"></ma-elem-history>
    </a>
    <a class="elem clear-history link link_underline link_red" *ngIf="data().history().length > 0" (click)="clearHistory()">
        Очистить историю поиска
    </a>
    <!--    </ng-template>-->
</ng-template>


