import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-svg-ok',
    templateUrl: './ok.component.svg',
    styles: [':host {display: block}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OkComponent {

}
