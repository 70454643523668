import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class CookieBrowser implements Storage {
    [index: number]: string;

    [key: string]: any;

    length: number;

    constructor(private cookieService: CookieService) {
    }

    public clear(): void {
        this.cookieService.deleteAll();
    }

    public getItem(key: string): string {
        return this.cookieService.get(key);
    }

    public key(index: number): string {
        return this.cookieService.getAll().propertyIsEnumerable[index];
    }

    public removeItem(key: string): void {
        this.cookieService.set(key, '', new Date(Date.now() - 15), '/');
        this.cookieService.delete(key, '/');
    }

    public setItem(key: string, data: string, domain?: string, expires?: number): void {
        let _days = 365;
        if (key === 'auth_token') {
            _days = 183;
        }
        if (expires) {
            _days = expires;
        }
        this.cookieService.set(key, data, new Date(Date.now() + 3600 * 24 * 1000 * _days), '/');
    }
}
