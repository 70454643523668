import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";
import {GroupsProductsInGet, GroupsProductsOutGet} from "@shared/service/api/groups/products/interface";

@Injectable()
export class GroupsProductsService {
    constructor(private _httpClient: HttpClient, private _city: CityService) {
    }

    /**
     *
     * @returns {Observable<GroupsProductsOutGet>}
     */
    get(_obj?: GroupsProductsInGet): Observable<GroupsProductsOutGet> {
        _obj["city_id"] = this._city.data.id;
        return this._httpClient.get<GroupsProductsOutGet>("ma/site/v1/groups/products", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
