import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {OutGet} from "./interface";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class CitiesLinkingService {

    constructor(private _request: ApiRequest, private _apiUtilsService: ApiUtilsService) {
    }

    /**
     *
     * @return {Promise<OutGet>}
     */
    get(): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/cities/linking', this._apiUtilsService.setCity({}))
            .then((res: Response) => res.json);
    }

}




