import {DialogConfig} from "@angular/cdk/dialog";

export const DIALOG_UI_SEARCH_CONFIG: DialogUiSearchDate = {
    maxWidth: "100vw",
    width: "100vw",
    maxHeight: "100%",
    height: "100%",
    closeOnNavigation: true,
    data: {
        clear: false,
    },
};

export interface DialogUiSearchDate extends DialogConfig {
    data: {
        clear: boolean;
    };
}
