import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ChatOperatorAvailableService {
    constructor(private _httpClient: HttpClient) {}

    /**
     *
     * @returns {boolean}
     */
    get(): Observable<boolean> {
        return this._httpClient.get<boolean>('ma/site/v1/chat/operator-available');
    }
}
