import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouteService} from "@shared/service/route/route.service";
import {NgIf} from "@angular/common";
import {FooterCatalogComponent} from "@shared/module_v2/layout/footer/catalog/catalog.component";
import {HelpComponent} from "@shared/module_v2/layout/footer/help/help.component";
import {MobileAppComponent} from "@shared/module_v2/layout/footer/mobile-app/mobile-app.component";
import {SocialNetworksComponent} from "@shared/module_v2/layout/footer/social-networks/social-networks.component";
import {CompanyComponent} from "@shared/module_v2/layout/footer/company/company.component";
import {
    CompanyInformationComponent,
} from "@shared/module_v2/layout/footer/company-information/company-information.component";
import {LicensesComponent} from "@shared/module_v2/layout/footer/licenses/licenses.component";
import {
    WarningContraindicationsComponent,
} from "@shared/module_v2/layout/footer/warning-contraindications/warning-contraindications.component";
import {PoliticsComponent} from "@shared/module_v2/layout/footer/politics/politics.component";
import {HelpMobileComponent} from "@shared/module_v2/layout/footer/help-mobile/help-mobile.component";
import {
    RecommendationTechnologiesComponent,
} from "@shared/module_v2/layout/footer/recommendation-technologies/recommendation-technologies.component";

@Component({
    selector: 'ma-layout-footer',
    standalone: true,
    imports: [
        HelpComponent,
        SocialNetworksComponent,
        FooterCatalogComponent,
        MobileAppComponent,
        PoliticsComponent,
        CompanyComponent,
        CompanyInformationComponent,
        LicensesComponent,
        HelpMobileComponent,
        WarningContraindicationsComponent,
        NgIf,
        RecommendationTechnologiesComponent,
    ],
    styleUrls: ['footer.component.less'],
    templateUrl: './footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RouteService
    ]
})

export class FooterComponent {

}

