import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";
import {ItemsSumInGet, ItemsSumOutGet} from "./interface";

@Injectable({providedIn: "root"})
export class ItemsSumService {
    constructor(private _httpClient: HttpClient, private _city: CityService) {
    }

    /**
     * Товары в корзине общая сумма
     *
     * @returns {Observable<ItemsSumOutGet>}
     */
    get(_obj: ItemsSumInGet): Observable<ItemsSumOutGet> {
        _obj["city_id"] = this._city.data.id;
        return this._httpClient.get<ItemsSumOutGet>("ma/site/v1/items/sum", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
