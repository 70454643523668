import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FaqInGet, FaqOutGet} from "./interface";

@Injectable({
    providedIn: "root",
})
export class FaqService {
    constructor(private _httpClient: HttpClient) {
    }

    /**
     *
     * @returns {Observable<FaqOutGet>}
     */
    get(_obj?: FaqInGet): Observable<FaqOutGet> {
        return this._httpClient.get<FaqOutGet>("ma/site/v1/faq", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
