import {inject, Injectable} from "@angular/core";
import {CitiesLocateInGet, CitiesLocateService} from "@shared/service/api/cities/locate";
import {CityService} from "@core/service/city/index";
import {Router} from "@angular/router";
import {Url} from "@core/service/url";
import {ORIGIN_HEADERS, ORIGIN_HREF, ORIGIN_USER_AGENT} from "@shared/injection-token/tokens";
import {RedirectService} from "@core/service/redirect";
import {Platform} from "@core/service/platform";
import {PopupYourCityService} from "@common/shared/service/popup-your-city/popup-your-city.service";

@Injectable({
    providedIn: "root",
})
export class CityLoadService {
    private cityService = inject(CityService);
    private citiesLocateService = inject(CitiesLocateService);
    private url = inject(Url);
    private router = inject(Router);
    private userAgent = inject(ORIGIN_USER_AGENT);
    private baseHref = inject(ORIGIN_HREF);
    private baseHeaders = inject(ORIGIN_HEADERS);
    private platform = inject(Platform);
    private redirectService = inject(RedirectService);
    private popupYourCityService = inject(PopupYourCityService);
    /**
     * Перед запуском запрашиваем данные города
     *
     * @returns {Promise<any[]>}
     */
    public load() {
        const obj: CitiesLocateInGet = {};

        const cityId = this.cityService.getCity();
        const cityNameUrl = this.getNameCityUrl();

        if (cityNameUrl) {
            obj.code = cityNameUrl;
        }
        if (cityId && !cityNameUrl) {
            obj.id = cityId;
        }

        // Определение google bot
        if (this.baseHeaders) {
            const userAgent = this.baseHeaders["user-agent"];
            if (userAgent?.indexOf("Googlebot") !== -1) {
                this.cityService.setGeolocation(true);
                this.popupYourCityService.setStatus();
            }
        }

        return new Promise((resolve) => {
            this.citiesLocateService.get(obj).subscribe((res) => {
                if (res.city) {
                    this.cityService.setCity(res.city, false);
                }

                if (res.redirect && res.redirect?.code) {
                    let urlR = "";
                    if (obj["code"]) {
                        // если в урл присутвует город которого нет по каким-то причинам у нас
                        const objUrl = this.url.parser(this.baseHref);
                        const regExp = new RegExp(obj["code"], "ig");
                        urlR = objUrl.pathname.replace(regExp, res.redirect.code);
                    } else {
                        urlR = res.redirect.code;
                    }
                    if (this.platform.browser) {
                        window.location.href = urlR;
                    } else {
                        this.redirectService._301(urlR);
                    }
                    resolve(true);
                }
                resolve(true);
            });
        }).catch(() => {
            // console.warn("Error");
        });
    }

    /**
     * Определяем есть в адресной строке город
     *
     * @returns {string}
     */
    getNameCityUrl(): string {
        const arrPath: string[] = ["landing-promotion"];

        this.router.config.forEach((routeMain) => {
            if (routeMain.path === "") {
                routeMain.children.forEach((v) => {
                    if (v?.data?.path) {
                        arrPath.push(v?.data?.path);
                    } else {
                        if (!arrPath.includes(v.path.split("/")[0])) {
                            arrPath.push(v.path.split("/")[0]);
                        }
                    }
                });
            } else {
                arrPath.push(routeMain.path);
            }
        });

        const objUrl = this.url.parser(this.baseHref);
        if (objUrl.pathname.length > 1) {
            const arr = objUrl.pathname.split("/").splice(1);
            if (arr.length >= 1) {
                if (!arrPath.includes(arr[0])) {
                    return arr[0];
                }
            }
        }

        return "";
    }
}
