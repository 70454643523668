import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {DIALOG_UI_SEARCH_CONFIG} from "@shared/module_v2/dialog/ui/search/search";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    public query$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    private queryParamMap$: Subscription = new Subscription();
    private dialog = inject(Dialog);
    private _activatedRoute = inject(ActivatedRoute);

    ngOnInit() {
        this.initSubs();
    }

    ngOnDestroy() {
        this.queryParamMap$.unsubscribe();
    }

    initSubs() {
        this.queryParamMap$ = this._activatedRoute.queryParamMap.subscribe((res) => {
            this.query$.next(res.has("q") ? res.get("q") : "");
        });
    }

    onFocusMobile() {
        this.openDialog(this.query$.value === "");
    }

    onClearValue() {
        this.openDialog(true);
        this.query$.next("");
    }

    openDialog(clear: boolean = false) {
        import("@shared/module_v2/dialog/ui/search/search.component").then((res) => {
            const options = DIALOG_UI_SEARCH_CONFIG;
            options.data.clear = clear;
            const dialogRef = this.dialog.open(res.UiDialogSearchComponent, options);
            dialogRef.closed.subscribe((res: string) => {
                this.query$.next(res);
            });
        });
    }
}
