@for (elem of images; track elem.src) {
    <div class="image">
        <img *maIfPlatformBrowser alt="{{ elem.alt }}" [priority]="false" [loading]="'lazy'" [ngSrc]="elem.src" width="42" height="42" />
    </div>
}
@if (onLastArrow) {
    <div class="image image__arrow">
        <svg-icon class="icon" [src]="'icons/svg/arrow-right-3.svg'" />
    </div>
}
