import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {AbstractControl, UntypedFormControl} from "@angular/forms";
import {of} from "rxjs/internal/observable/of";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
    selector: "app-input-phone",
    templateUrl: "phone.component.html",
    styleUrls: ["phone.component.less"],
})
export class PhoneComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() placeholder: string = "+7 (___) ___ ____";
    @Input() inputClass: string = "";
    @Input() focus: boolean = false;
    @Input() setError: boolean = false;
    @Input() showError: boolean = true;
    @Input() tabindex: string = "";
    @Input() borderColor: string = "";

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild("phone") elemInput: ElementRef;

    public customPatterns = {
        "9": {
            pattern: new RegExp("^9"),
        },
        "0": {
            pattern: new RegExp("[0-9]"),
        },
    };
    public name: string = "phone";
    public phone = "";

    private submit$: Subscription;
    private valueChanges$: Subscription;
    public submit: boolean = false;
    public value = "";
    public isNotActiveInput = true;

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }

        if (this.control.parent.get("submit") instanceof UntypedFormControl) {
            this.submit = this.control.parent.get("submit").value;
            this.submit$ = this.control.parent.get("submit").valueChanges.subscribe((res) => {
                this.submit = res;
            });
        }

        if (this.control.value) {
            this.value = this.control.value;
        }

        this.valueChanges$ = this.control.valueChanges
            .subscribe((res) => {
                this.value = res;
                if(this.control.valid){
                    this.isNotActiveInput = false;
                }
            });
        this.control.setAsyncValidators((control: AbstractControl) => {
            let value: string = control.value;

            if (value) {
                value = value.replace(/[^0-9]/ig, "");
                const test = new RegExp(/[0-9]{11}/i);
                if (!test.test(value)) {
                    return of({phone: true});
                } else if (value.length === 11) {
                    return of(null);
                } else {
                    return of(null);
                }
            }
            return of({phone: true});
        });
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
        if (this.valueChanges$) {
            this.valueChanges$.unsubscribe();
        }
    }

    /**
     * Обработка кнопки "Enter"
     */
    evenEnter(): void {
        this.enter.emit(true);
    }

    eventFocus() {
        this.isNotActiveInput = false;
    }

    eventClick() {
        this.isNotActiveInput = false;
    }

    eventBlur() {
        this.isNotActiveInput = !this.control.value;
    }

    valueChange(str: string) {
        if (str.length > 0) {
            this.control.setValue(`7${str}`);
        } else {
            this.control.setValue("");
        }
    }
}
