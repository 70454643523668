/* Service */
import {NgModule} from "@angular/core";
import {CityService} from "./service/city";
import {Config} from "./service/config";
import {LogService} from "./service/logger";
import {SessionService} from "./service/session/session.service";
import {LsService} from "./service/localstorage";
import {Checking} from "./service/checking";
import {Refresh} from "./service/refresh";
import {UserService} from "./service/user/";
import {Toast} from "./service/toast";
import {StoreService} from "./service/store";
import {WindowRefService} from "./service/window";
import {Platform} from "./service/platform";
import {HttpStatus} from "./service/http-status";
import {Cookie} from "./service/cookie";
import {MailAnalyticsService} from "./service/mail-analytics";
import {Url} from "@core/service/url";

/*Module*/
import {CookieService} from "ngx-cookie-service";
import {ToastrModule} from "ngx-toastr";
import {HttpClientModule} from "@angular/common/http";
import {LazyScript} from "./service/lazy-script";
import {YandexMetrikaService} from "./service/yandex-metrika";
import {RegionsService} from "@core/service/regions";
import {MobileService} from "@core/service/mobile";
import {MetaTagService} from "@core/service/meta-tag";
import {RedirectService} from "@core/service/redirect";
import {Api2Module} from "@core/service/api2/module";
import {GoogleModule} from "@core/module/google/module";
import {UserAgentService} from "@core/service/user-agent";
import {TelemetryService} from "@core/service/telemetry";
import {FavoritesService} from "@core/service/favorites";
import {AutorunService} from "@core/service/autorun/autorun.service";
import {EpnService} from "@core/service/epn/epn.service";
import {ParametersService} from "@core/service/parameters";
import {ResizeService} from "@core/service/resize/resize.service";
import {TalkMeService} from "@shared/service/talk-me/talk-me";
import {UtmService} from "@core/service/utm/utm.service";
import {SizeService} from "@core/service/size/size.service";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {Resize2Service} from "@core/service/resize2/resize2.service";

@NgModule({
    imports: [
        ToastrModule.forRoot({
            maxOpened: 5,
        }),
        HttpClientModule,
        Api2Module,
        GoogleModule,
    ],
    declarations: [],
    providers: [
        CityService,
        Config,
        LogService,
        SessionService,
        LsService,
        Checking,
        Refresh,
        UserService,
        Toast,
        StoreService,
        WindowRefService,
        Platform,
        HttpStatus,
        Cookie,
        LazyScript,
        RegionsService,
        YandexMetrikaService,
        MobileService,
        MetaTagService,
        MailAnalyticsService,
        Url,
        CookieService,
        RedirectService,
        UserAgentService,
        TelemetryService,
        FavoritesService,
        AutorunService,
        EpnService,
        ParametersService,
        ResizeService,
        Resize2Service,
        TalkMeService,
        UtmService,
        SizeService,
        DeviceTokenService,
    ],
    exports: [],
})
export class CoreModule {}
