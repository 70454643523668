import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouterLink} from "@angular/router";

@Component({
    standalone: true,
    selector: "app-company-information",
    templateUrl: "./company-information.component.html",
    styleUrls: ["./company-information.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
    ],
})
export class CompanyInformationComponent {

}
