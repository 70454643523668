import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    standalone: true,
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyComponent {
    public year = new Date().getFullYear();
}
