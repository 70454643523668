import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {ImageLineComponent} from "./image-line.component";
import {IfPlatformBrowserDirective} from "@common/shared/directive/if-platform-browser.directive";
import {SvgIconComponent} from "angular-svg-icon";

@NgModule({
    declarations: [ImageLineComponent],
    exports: [ImageLineComponent],
    imports: [CommonModule, NgOptimizedImage, IfPlatformBrowserDirective, SvgIconComponent],
})
export class ImageLineModule {}
