<input
    #code
    type="text"
    name="code"
    placeholder="{{placeholder}}"
    [class]="inputClass"
    [formControl]="control"
    (keyup.enter)="evenEnter()"
    [class.error]="(control.getError('code') === true && control.dirty) || (control.getError('code') !== null && submit || setError === true)"
    [class.succ]="control.valid"
/>
