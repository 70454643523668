import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    standalone: true,
    selector: 'app-warning-contraindications',
    templateUrl: './warning-contraindications.component.html',
    styleUrls: ['./warning-contraindications.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningContraindicationsComponent {
}
