import {inject, Injectable, signal} from "@angular/core";
import {OrdersDialogOutGet, OrdersDialogService} from "@shared/service/api/orders/dialog";

@Injectable({
    providedIn: "root",
})
export class ActiveOrdersService {
    private ordersDialogService = inject(OrdersDialogService);

    private _count = signal<number>(0);
    private _list = signal<OrdersDialogOutGet["list"]>([]);

    public count = this._count.asReadonly();
    public list = this._list.asReadonly();

    public setCount(count: number) {
        this._count.set(count);
    }

    public load() {
        this.updateList();
    }

    public updateList() {
        this.ordersDialogService
            .get()
            .subscribe((v) => {
                this._list.set(v.list);
                this.setCount(v.list.length);
            });
    }
}
