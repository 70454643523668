import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SearchHeaderHintsComponent} from "./search-header-hints.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {ElemHistoryComponent} from "./elem-history/elem-history.component";
import {ElemProductComponent} from "./elem-product/elem-product.component";
import {ElemItemComponent} from "./elem-item/elem-item.component";
import {ImageLineModule} from "@shared/module_v2/ui/image-line/image-line.module";
import {RouterModule} from "@angular/router";
import {SharedDirectiveModule} from "@shared/shared.directive";
import {UiStickerColorComponent} from "@shared/module_v2/ui/sticker-color/sticker-color.component";
import {ColorRandomPipe} from "./color-random.pipe";
import {QueryParamProductPipe} from "./query-param-product.pipe";

@NgModule({
    declarations: [
        SearchHeaderHintsComponent,
        ElemHistoryComponent,
        ElemProductComponent,
        ElemItemComponent,
        ColorRandomPipe,
        QueryParamProductPipe
    ],
    exports: [
        SearchHeaderHintsComponent,
    ],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        ImageLineModule,
        RouterModule,
        SharedDirectiveModule,
        UiStickerColorComponent,
    ],
})
export class SearchHeaderHintsModule {
}
