import {Injectable} from '@angular/core';
import {LazyScript} from '@core/service/lazy-script';
import {Config} from '@core/service/config';
import {Platform} from '@core/service/platform';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

declare const gtag: any;

@Injectable({providedIn: 'root'})

export class GoogleService {
    private config;

    /**
     * true - загружен уже
     * null - не загружен еще
     *
     * @private
     */
    public downloadScriptStatus: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private _lazyScript: LazyScript,
                private _platform: Platform,
                private _config: Config) {
        this.config = this._config.get('google');
    }

    public run() {

        this._lazyScript
            .load('https://www.googletagmanager.com/gtag/js?id=' + this.config['id'], true)
            .then((res) => {
                if (res) {
                    gtag('js', new Date());
                    gtag('config', this.config['id']);
                    this.downloadScriptStatus.next(true);
                }
                return res;
            });
    }

}
