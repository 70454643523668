import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-html-a, .app-html-a',
    templateUrl: './a.component.html',
    styleUrls: ['./a.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AComponent {
    @Input() routerLink: string | string[];
    @Input() title: string | undefined;
    @Input() target: string | undefined;
    @Input() link: string | undefined;
    @Input() rel: string | undefined;
}
