import {Injectable} from "@angular/core";
import {Cookie} from "../cookie";

@Injectable()
export class Storage {
    private nameCity = 'city';
    private nameGeoLocation = 'geolocal';

    private nameAdulteryCity = 'adultery_city';

    constructor(protected _cookie: Cookie) {
    }

    /**
     * Значение города
     *
     * @returns {string}
     */
    protected get city(): string {
        return this.get(this.nameCity);
    }

    /**
     * Запись города
     *
     * @returns {string}
     */
    protected set city(code: string) {
        this.set(this.nameCity, code);
    }

    /**
     * Значение гео данных
     *
     * @returns {string}
     */
    protected get geolocal(): string {
        return this.get(this.nameGeoLocation);
    }

    /**
     * Запись гео данных
     *
     * @returns {string}
     */
    protected set geolocal(value: string) {
        this.set(this.nameGeoLocation, value);
    }


    /**
     * Значение информации о показе баннера
     *
     * @returns {string}
     */
    protected get adulteryCity(): string {
        return this.get(this.nameAdulteryCity);
    }

    /**
     * Запись информации о показе баннера
     *
     * @returns {string}
     */
    protected set adulteryCity(value: string) {
        this.set(this.nameAdulteryCity, value);
    }
    /**
     * Запись значение в хранилище
     *
     * @param {string} name
     * @param {string} value
     */
    private set(name: string, value: string): void {
        this._cookie.setItem(name, value);
    }

    /**
     * Возращает значение из хранилище
     *
     * @param {string} name
     * @returns {string}
     */
    private get(name: string): string {
        return this._cookie.getItem(name);
    }
}
