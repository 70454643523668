import {CONFIG} from "./app.config";
import {APP_INITIALIZER_BROWSER} from "@common/core/app-initializer/browser";
import {RedirectV2Inject} from "@common/core/service/redirect/inject";
import {RedirectBrowserService} from "@common/core/service/redirect/redirect-browser.service";
import {CONFIG_CORE_BROWSER} from "@common/core/config/browser";

/**
 * Конфиг только для браузерной версии сайта
 */
export const CONFIG_BROWSER = [
    ...CONFIG,
    ...APP_INITIALIZER_BROWSER,
    ...CONFIG_CORE_BROWSER,
    {provide: RedirectV2Inject, useClass: RedirectBrowserService},
];
