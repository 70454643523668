import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import {UiFloatingBlockBottomService} from "@shared/module_v2/ui/floating-block-bottom/floating-block-bottom.service";
import {filter} from "rxjs/operators";
import {ResizeService} from "@core/service/resize/resize.service";

@Component({
    selector: 'ma-ui-floating-block-bottom',
    templateUrl: './floating-block-bottom.component.html',
    styleUrls: ['./floating-block-bottom.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingBlockBottomComponent implements OnInit {
    public listTemplateRef: TemplateRef<any>[] = [];

    @ViewChild('dynamic', {read: ViewContainerRef}) private viewRef: ViewContainerRef;

    private componentRef: ComponentRef<any>;

    constructor(private _elementRef: ElementRef,
                private _cdr: ChangeDetectorRef,
                private _resize: ResizeService,
                private _UiFooterService: UiFloatingBlockBottomService) {
    }

    ngOnInit(): void {
        this.setMarginFooter();
        this._UiFooterService.eventRef()
            .pipe(filter(res => res !== null))
            .subscribe((res) => {
                if(this.viewRef){
                    this.viewRef.clear();
                }

                if(res.length > 0){
                    res.map((v) => {
                        this.create(v);
                    });
                }
                this._cdr.detectChanges();
                this.setMarginFooter();
            });


        this._resize.resize$.subscribe(() => {
            this.setMarginFooter();
        });
    }

    create(res) {
        this.componentRef = this.viewRef.createComponent(res.comp);
        if (res.data) {
            for (const prop in res.data) {
                // eslint-disable-next-line no-prototype-builtins
                if (!res.data.hasOwnProperty(prop)) {
                    continue;
                }
                this.componentRef.instance[prop] = res.data[prop];
            }
        }
    }

    setMarginFooter() {
        const heightBlock = this._elementRef.nativeElement.clientHeight;
        const parentBlock = this._elementRef.nativeElement.parentNode;
        parentBlock['style'] = `padding-bottom: ${heightBlock || 0}px;`;
    }
}
