import {ChangeDetectionStrategy, Component, HostBinding, HostListener, Inject} from "@angular/core";
import {PageScrollService} from "ngx-page-scroll-core";
import {DOCUMENT} from "@angular/common";
import {Platform} from "@core/service/platform";

@Component({
    selector: 'app-ui-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollTopComponent {

    @HostListener('click') onClickUp() {
        if (this._platform.browser) {
            this._pageScrollService.scroll({document: this._document, scrollTarget: "body", duration: 300});
        }
    }

    @HostListener('window:scroll') onScroll() {
        if (this._platform.browser) {
            const number = window.pageYOffset
                || document.documentElement.scrollTop
                || document.body.scrollTop || 0;

            this.active = number > 0;
        }
    }

    @HostBinding('class.active') active: boolean = false;

    constructor(private _pageScrollService: PageScrollService,
                private _platform: Platform,
                @Inject(DOCUMENT) private _document: Document) {
    }
}
