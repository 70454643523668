import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";
import {SearchSuggestionsInGet, SearchSuggestionsOutGet} from "./interface";

@Injectable({
    providedIn: "any"
})
export class SearchSuggestionsService {
    constructor(private _httpClient: HttpClient,
                private _city: CityService) {
    }

    /**
     *
     * @returns {Observable<SearchItemsOutGet>}
     */
    get(_obj: SearchSuggestionsInGet): Observable<SearchSuggestionsOutGet> {
        _obj["city_id"] = this._city.data.id;
        return this._httpClient.get<SearchSuggestionsOutGet>("ma/site/v1/search/suggestions", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
