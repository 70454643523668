<div>На информационном ресурсе megapteka.ru применяются <a title="Рекомендательные технологии" class="link_underline"
                                                           routerLink="/recommendation-technologies">рекомендательные
    технологии</a> (информационные технологии предоставления информации на основе сбора, систематизации и анализа
    сведений, относящихся к предпочтениям пользователей сети «Интернет», находящихся на территории РФ).
</div>
<div>ООО «Платфомни» использует файлы «cookie» для повышения удобства пользования сайтом. Используемый Вами браузер и
    (или) устройство может позволять блокировать, удалять или иным образом ограничивать использование файлов «cookie».
    Вы можете ознакомиться с дополнительной информацией в
    <a routerLink="/terms" class="link link_underline">Политике конфиденциальности</a>.
</div>
<div>Источник информации об описаниях лекарственных средств и БАДов: Регистр Лекарственных Средств России-РЛС®</div>
