import {Directive, HostListener, Input} from "@angular/core";
import {YandexMetrikaService} from "@core/service/yandex-metrika";

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[ymReachGoal]'})

export class YmReachGoalDirective {
    @Input() ymReachGoal = '';

    constructor(private _ya: YandexMetrikaService) {
    }

    @HostListener('click') onClick() {
        if (this.ymReachGoal.length > 0) {
            this._ya.reachGoal(this.ymReachGoal);
        }
    }
}
