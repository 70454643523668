import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DotsComponent} from './dots.component';


@NgModule({
    declarations: [
        DotsComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [DotsComponent]
})
export class SvgDotsModule {
}
