import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: "any"
})
export class AuthLogoutService {
    constructor(private _httpClient: HttpClient) {}

    /**
     *
     *
     * @returns {Observable<void>}
     */
    put(): Observable<void> {
        return this._httpClient.put<void>('ma/site/v1/auth/logout', {});
    }
}

