import {inject, Injectable, signal} from "@angular/core";
import {LsService} from "@core/service/localstorage";

@Injectable({
    providedIn: "root",
})
export class SearchHeaderHintsHistoryService {
    private readonly NAME: string = "search_query";
    private _lsService = inject(LsService);
    public history = signal<string[]>([]);

    add(name: string) {
        this.get()
            .then(() => {
                const list = this.history();
                if (list.indexOf(name) === -1) {
                    list.unshift(name);
                    if (list.length > 4) {
                        list.pop();
                    }
                }
                this._lsService.set(this.NAME, JSON.stringify(list));
                this.history.set(list);
            });
    }

    get(): Promise<string[]> {
        return new Promise((resolve, reject) => {
            try {
                let list = [];
                const store = this._lsService.get("search_query");
                if (store) {
                    list = JSON.parse(store);
                }
                this.history.set(list);
                resolve(list);
            } catch (e) {
                this.history.set([]);
                reject([]);
            }
        });

    }

    clear() {
        this._lsService.remove(this.NAME);
        this.history.set([]);
    }
}
