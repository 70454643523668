import {AfterContentChecked, Component, HostBinding, HostListener, Input} from "@angular/core";

@Component({
    selector: "ma-button-general, .ma-button-general, button[maButtonGeneral], a[maButtonGeneral]",
    templateUrl: "./general.component.html",
    styleUrls: ["./general.component.less"],
})
export class GeneralComponent implements AfterContentChecked {
    @Input() loading: boolean = false;

    @HostBinding("class.loading") classLoading: boolean = false;

    @HostListener("click", ["$event"])
    onClickHost() {
        if (!this.loading) {
            this.classLoading = true;
        }
    }

    ngAfterContentChecked() {
        this.classLoading = this.loading;
    }
}
