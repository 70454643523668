import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouterLink} from "@angular/router";

@Component({
    standalone: true,
    selector: "app-help-mobile",
    templateUrl: "./help-mobile.component.html",
    styleUrls: ["./help-mobile.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
    ],
})
export class HelpMobileComponent {

}
