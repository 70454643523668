import {Injectable} from "@angular/core";
import {LsService} from "@core/service/localstorage";

@Injectable()
export class Storage {
    private name = 'list';

    constructor(protected _storage: LsService) {
    }

    /**
     * Запись значение в хранилище
     *
     * @param {number[]} value
     */
    protected setStorage(value: number[]) {
        return this._storage.set(this.name, JSON.stringify(value));
    }

    /**
     * Возращает значение из хранилище
     *
     * @returns {number[]}
     */
    protected getStorage(): number[] {
        try {
            const st = JSON.parse(this._storage.get(this.name));
            if (!st) {
                return [];
            }
            return JSON.parse(this._storage.get(this.name));
        } catch (e) {
            return [];
        }

    }
}
