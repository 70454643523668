import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SizeService {
    calcArray(list: any[]): { mb: number, kb: number } {
        let total = 0;
        for (const x of Object.keys(list)) {
            let len: number = 0;
            // eslint-disable-next-line no-prototype-builtins
            if (!list.hasOwnProperty(x)) {
                continue;
            }
            len = ((list[x].length + x.length) * 2);
            total += len;
        }
        if (total > 0) {
            return {
                mb: +(total / 1024 / 1024).toFixed(2),
                kb: +(total / 1024).toFixed(2)
            };
        }

        return {
            mb: 0,
            kb: 0
        };
    }
}
