import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {ResizeService} from "@core/service/resize/resize.service";
import {Platform} from "@core/service/platform";
import {Subscription} from "rxjs/internal/Subscription";
import {Observable} from "rxjs/internal/Observable";
import {fromEvent} from "rxjs";

/**
 * @deprecated
 */
@Component({
    selector: 'app-html-image-lazy, .c-html-image-lazy',
    templateUrl: './image-lazy.component.html',
    styleUrls: ['./image-lazy.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLazyComponent implements OnInit {
    @Input() offset: number = 80;
    @Input() style: string = '';
    @Input() imageSrc: string = '';
    @Input() defaultImage: string = '';
    @Input() class: string = '';
    @Input() alt: string = '';
    @Input() srcMobile: string = '';
    @Input() srcDesktop: string = '';
    @Input() sizeMobile: any[] = [];
    @Input() sizeDesktop: any[] = [];
    @Input() nopic: string = '';

    private _resizeService$: Subscription;

    public srcM: string = '';
    public srcD: string = '';
    public src: string = '';
    public width: string = null;
    public height: string = null;
    public default: string = '';

    public scroll$: Observable<any>;

    constructor(private _resizeService: ResizeService, private _platform: Platform, private _cdr: ChangeDetectorRef) {
        if (this._platform.browser) {
            this.scroll$ = fromEvent(window, "scroll");
        }
    }

    ngOnInit() {
        this.srcM = this.srcMobile;
        this.srcD = this.srcDesktop;
        this._resizeService$ = this._resizeService
            .resize$
            .subscribe((res) => {
                if (res.mobile) {
                    this.width = this.sizeMobile[0];
                    this.height = this.sizeMobile[1];
                    this.src = this.srcMobile;
                } else {
                    this.width = this.sizeDesktop[0];
                    this.height = this.sizeDesktop[1];
                    this.src = this.srcDesktop;
                }
                this._cdr.detectChanges();
            });
    }

}

