import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../request";
import {InGet, OutGet} from "./interface";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class ProductsRandomService {

    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     * Список
     *
     * @param {InGet} obj
     * @returns Promise<OutGet>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/products/random', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }
}

