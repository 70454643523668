import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: 'app-svg-arrow-up',
    templateUrl: './arrow-up.component.svg',
    styles: [':host {display: block}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowUpComponent {
}
