import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MenuCatalogComponent} from "./menu-catalog.component";
import {RouterModule} from "@angular/router";
import {ListComponent} from "./list/list.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {LinkCityDirective} from "@shared/directive/link-city.directive";
import {ColumnCountPipe} from "@component/layout/menu-catalog/list/column-count.pipe";

@NgModule({
    declarations: [
        MenuCatalogComponent,
        ListComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AngularSvgIconModule,
        LinkCityDirective,
        ColumnCountPipe,
    ],
    exports: [
        MenuCatalogComponent,
    ],
})
export class LayoutMenuCatalogModule {
}
