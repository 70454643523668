import {Pipe, PipeTransform} from "@angular/core";
import {formatDate} from "@angular/common";

type type = "short" | "shortDate" | "mediumDate" | "longDate";

@Pipe({
    name: "dateSite",
})
export class DateSitePipe implements PipeTransform {
    transform(_date?: number | string, type: type = "shortDate"): string {
        if (typeof _date === "number") {
            let format = "dd.MM.yyyy";
            switch (type) {
                case "mediumDate":
                    format = "dd MMMM";
                    break;

                case "longDate":
                    format = "dd MMMM yyyy";
                    break;

                case "short":
                    format = "H:mm dd.MM.yyyy";
                    break;

                default:
            }

            return formatDate(_date * 1000, format, "ru-Ru", 'UTC');
        }

        return _date;
    }
}
