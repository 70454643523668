import {Inject, Injectable, Optional} from "@angular/core";
import {Options} from "@core/service/http-status/interface";
import {Platform} from "@core/service/platform";
import {RESPONSE} from "@common/tokens/express.tokens";

@Injectable()
export class HttpStatus {
    constructor(@Optional() @Inject(RESPONSE) private _response: any, private _platform: Platform) {}

    public httpCode(code: number, obj?: Options) {
        if (this._platform.server) {
            switch (code) {
                case 404:
                    this._response.statusCode = 404;
                    this._response.statusMessage = "Not Found";

                    break;
                case 301:
                    this._response.statusCode = 301;
                    this._response.statusMessage = "Moved Permanently";
                    this._response.header("Location", obj.location);

                    break;
                default:
                    this._response.statusCode = 200;
                    this._response.statusMessage = "OK";

                    break;
            }
        }
    }
}
