import {ChangeDetectionStrategy, Component, inject, OnInit} from "@angular/core";
import {MobileService} from "@core/service/mobile";
import {SmartBannerService} from "@common/shared/service/smart-banner/smart-banner.service";

@Component({
    selector: "app-ui-smart-banner",
    templateUrl: "./smart-banner.component.html",
    styleUrls: ["smart-banner.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSmartBannerComponent implements OnInit {
    private smartBannerService = inject(SmartBannerService);
    private mobileService = inject(MobileService);
    public show: boolean = false;

    ngOnInit() {
        const status = this.smartBannerService.getStatus();
        if (!status) {
            this.show = !this.mobileService.isApple;
        }
    }

    onClose() {
        this.smartBannerService.setStatus();
        this.show = false;
    }
}
