import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {Storage} from '@core/service/epn/epn.storage';
import {CookieService} from 'ngx-cookie-service';

@Injectable()

export class EpnService extends Storage {

    constructor(private _activatedRoute: ActivatedRoute,
                private _router: Router,
                protected _cookie: CookieService) {
        super(_cookie);
    }

    run() {
        this._activatedRoute
            .queryParams
            .pipe(take(2))
            .subscribe((res) => {
                if (res['utm_source'] === 'epn' && res['user_id'] && res['click_id']) {
                    this.setUserId(res['user_id']);
                    this.setClickId(res['click_id']);

                    this._router.navigate(
                        ['.'],
                        {
                            relativeTo: this._activatedRoute, queryParams: {
                                user_id: null,
                                click_id: null,
                                utm_source: null
                            }
                        }
                    );
                }
            });
    }

    get(): { click_id: string, user_id: string } {
        return {
            click_id: this.getClickId(),
            user_id: this.getUserId()
        };
    }
}
