<div class="wrapper">
    <div class="flex">
        <a routerLink="/" class="logo-big ma-svg-logo-ma" [width]="'231'" [height]="'25'"></a>
        <ma-ui-search-header [isFloating]="true" class="search-header search-header_floating"></ma-ui-search-header>
        <ma-ui-basket-small class="bg-red" [inFixed]="true"></ma-ui-basket-small>
    </div>
    <div class="dynamic">
        <ng-template #dynamic></ng-template>
    </div>
</div>
