<div class="header-basket-dd down {{class}}"  *ngIf="list?.length > 0">
    <div class="header-basket-dd-inn">
        <div class="header-basket-dd-inn-scroll">
            <div class="header-basket-dd-elem" *ngFor="let item of list">
                <div class="header-basket-dd-elem-img">
                    <img ngSrc="{{item.images?.length > 0 ? item.images[0] : '/images/nopic.png'}}" [fill]="true" alt="{{item.name}}">
                </div>
                <div class="header-basket-dd-elem-info">
                    <div class="header-basket-dd-elem-info-h">{{item.name}}</div>
                    <div class="header-basket-dd-elem-info-text" *ngIf="item.is_available || item.is_available_from_stock">
                        от {{ item.price | formatPrice }} × {{ item.quantity }}
                    </div>
                    <div *ngIf="!item.is_available && !item.is_available_from_stock" class="header-basket-dd-elem-info-text">Нет в наличии
                    </div>
                </div>
            </div>
        </div>
        <div class="header-basket-dd-wrn" *ngIf="(_basket.sum$ | async) < MIN_SUM_ORDER">Минимальная сумма заказа {{MIN_SUM_ORDER}} рублей</div>
        <ng-container *ngIf="isMinMax">
            <div class="header-basket-dd-end">
                <div class="header-basket-dd-end-elem" *ngIf="!isMinMax.isFull">
                    <div class="header-basket-dd-end-left">Итого:</div>
                    <div class="header-basket-dd-end-right">от {{ isMinMax.sumNotAll | number:'1.0-0' }}</div>
                    <div class="header-basket-dd-end-info"><div class="header-basket-dd-end-info">(если забрать товары из {{isMinMax.countStoresNotAll}} разных аптек)</div></div>
                </div>
                <div class="header-basket-dd-end-elem" *ngIf="isMinMax.isFull">
                    <div class="header-basket-dd-end-left"> </div>
                    <div class="header-basket-dd-end-right">от {{ isMinMax.sumAll | number:'1.0-0' }}</div>
                    <div class="header-basket-dd-end-info">(если забрать все товары в одной аптеке)</div>
                </div>
                <div class="header-basket-dd-end-elem" *ngIf="!isMinMax.isFull">
                    <div class="header-basket-dd-end-left"></div>
                    <div class="header-basket-dd-end-right">Ни в одной аптеке нет полного набора</div>
                </div>
            </div>
        </ng-container>

        <div class="header-basket-dd-endagain">
            <div class="header-basket-dd-endagain-btn">
                <a maButtonGeneral class="button lg uppercase" [routerLink]="['/basket']">ПЕРЕЙТИ В КОРЗИНУ</a>
            </div>
        </div>
    </div>
</div>
