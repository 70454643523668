import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SearchHeaderComponent} from "./search-header.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {ButtonGeneralModule} from "@shared/module_v2/button/general/general.module";
import {SearchHeaderHintsModule} from "@shared/module_v2/ui-request/search-header-hints/search-header-hints.module";
import {FormsModule} from "@angular/forms";


@NgModule({
    declarations: [
        SearchHeaderComponent,
    ],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        ButtonGeneralModule,
        SearchHeaderHintsModule,
        FormsModule,
    ],
    exports: [
        SearchHeaderComponent,
    ]
})
export class UiSearchHeaderModule {
}
