import {Injectable, Injector} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {Platform} from "@core/service/platform";

@Injectable({
    providedIn: "root"
})
export class LazyStyleService {
    private _document: Document;
    private _platform: Platform;

    constructor(private _injector: Injector) {
        this._document = _injector.get(DOCUMENT);
        this._platform = _injector.get(Platform);
    }

    /**
     * Загружает стили
     *
     * @param {string} nameStyle
     *
     * @returns {Promise<any>}
     */
    public load(nameStyle: string): void {

        const head = this._document.getElementsByTagName("head")[0] as HTMLLinkElement;

        const themeLink = this._document.getElementById(nameStyle) as HTMLLinkElement;
        if (!themeLink && this._platform.browser) {
            const style: HTMLLinkElement = this._document.createElement("link");
            style.id = nameStyle;
            style.rel = "stylesheet";
            style.media = "print";
            style.onload = function() {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.media = 'all';
            };
            style.href = `${nameStyle}.css`;
            head.appendChild(style);
        }
    }
}
