import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {BasketService} from "@common/core/service/basket";
import {ParametersService} from "@core/service/parameters";
import {BasketItems} from "@model/basket-items";

@Component({
    selector: "ma-basket-small-list",
    templateUrl: "./list.component.html",
    styleUrls: ["list.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketSmallListComponent implements OnInit {
    public list: BasketItems[] = [];
    public MIN_SUM_ORDER: number = 0;
    public isMinMax;

    @Input() count: number;
    @Input() class: string = "";

    constructor(
        public _basket: BasketService,
        private _parametersService: ParametersService,
    ) {}

    ngOnInit() {
        this.MIN_SUM_ORDER = this._parametersService.parametersData.ORDER_MIN_SUM;
        this.list = this._basket.list$.getValue();
    }
}
