import {ChangeDetectionStrategy, Component, inject, OnInit} from "@angular/core";
import {CityService} from "@core/service/city";
import {City} from "@core/model/city";
import {Dialog} from "@angular/cdk/dialog";
import {DIALOG_UI_CITY_SELECTION_CONFIG} from "@shared/module_v2/dialog/ui/city-selection/city-selection";
import {PopupYourCityService} from "@common/shared/service/popup-your-city/popup-your-city.service";
import {Platform} from "@core/service/platform";

@Component({
    selector: "app-ui-popup-your-city",
    templateUrl: "./popup-your-city.component.html",
    styleUrls: ["./popup-your-city.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupYourCityComponent implements OnInit {
    public city: City;
    public isActive: boolean = false;
    private readonly NAME_STORAGE: string = "ma_session_change_city";
    private dialog = inject(Dialog);
    private popupYourCityService = inject(PopupYourCityService);
    private platform = inject(Platform);
    private _city = inject(CityService);

    ngOnInit() {
        if (this.platform.browser) {
            this.city = this._city.city$.getValue();
            if (!this.popupYourCityService.getStatus()) {
                this.isActive = true;
                this.popupYourCityService.setStatus();
            }
        }
    }

    onClickYes() {
        this.isActive = false;
    }

    onClickNo() {
        this.isActive = false;
        import("@shared/module_v2/dialog/ui/city-selection/city-selection.component").then((res) => {
            this.dialog.open(res.DialogCitySelectionComponent, DIALOG_UI_CITY_SELECTION_CONFIG);
        });
    }
}
