import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InGet, OutGet} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class ItemsHighDemandService {
    constructor(private _request: ApiRequest) {
    }

    /**
     * @param {InGet} obj
     * @returns {Promise<OutGet>}
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v3/items/high-demand', obj)
            .then((res: Response) => res.json);
    }
}

