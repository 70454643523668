import {Pipe, PipeTransform} from "@angular/core";
import {TextService} from "@shared/service/text";

@Pipe({
    name: 'numEnding'
})
export class NumEndingPipe implements PipeTransform {
    constructor(private _text: TextService) {}

    transform(_number: number, _words: string[]) {
        return this._text.transform(_number, _words);
    }
}
