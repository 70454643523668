import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UiSmartBannerComponent} from "@shared/module_v2/ui/smart-banner/smart-banner.component";
import {SvgCloseModule} from "@shared/module_v2/svg/close/close.module";


@NgModule({
    declarations: [
        UiSmartBannerComponent,
    ],
    imports: [
        CommonModule,
        SvgCloseModule
    ],
    exports: [
        UiSmartBannerComponent,
    ],
})
export class UiSmartBannerModule {
}
