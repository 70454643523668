import {inject, Injectable} from "@angular/core";
import {Cookie} from "@core/service/cookie";

@Injectable({
    providedIn: "root",
})
export class PopupYourCityService {
    private cookie = inject(Cookie);
    private readonly NAME_VIEW_BANNER = "view_banner";
    private status = false;

    public load() {
        this.status = this.getViewBanner();
    }

    /**
     * Запрос информации о показе баннера
     */
    public getStatus() {
        return this.status;
    }

    /**
     * Установка баннера
     *
     */
    public setStatus(): void {
        this.cookie.setItem(this.NAME_VIEW_BANNER, "1");
    }

    /**
     * Запрос информации о показе баннера
     *
     * @returns {boolean}
     */
    private getViewBanner(): boolean {
        return this.cookie.getItem(this.NAME_VIEW_BANNER) === "1";
    }
}
