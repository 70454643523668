import {Inject, Injectable} from "@angular/core";
import {Config} from "@core/service/config";
import {REQUEST} from "@common/tokens/express.tokens";
import {Platform} from "@core/service/platform";
import {ApiService} from "@core/service/api2";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {CityService} from "@core/service/city";
import {skip, take} from "rxjs/operators";
import {TalkMeService} from "@shared/service/talk-me/talk-me";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {InPut} from "@core/service/api2/methods/auth/telemetry/interface";

@Injectable()
export class TelemetryService {
    private deviceToken: string = "";

    constructor(
        private _config: Config,
        private _platform: Platform,
        private _api: ApiService,
        private _city: CityService,
        private _yandexMetrika: YandexMetrikaService,
        private _talkMeService: TalkMeService,
        private _deviceToken: DeviceTokenService,
        @Inject(REQUEST) private request: any,
    ) {
        this._city.city$.pipe(skip(1)).subscribe(() => {
            this.switchCity();
        });
    }

    // После загрузки страницы первого раза
    identification() {
        this.deviceToken = this._deviceToken.get();

        if (this._platform.server) {
            this._api.authTelemetry.put({device_token: this.deviceToken});
        } else {
            this._yandexMetrika
                .getClientID()
                .pipe(take(1))
                .subscribe((id) => {
                    const yaObj: InPut = {
                        device_token: this.deviceToken,
                    };
                    if (id) {
                        yaObj.yandexmetrica_id = id;
                    }

                    this._api.authTelemetry.put(yaObj);
                    this._yandexMetrika.setUserID(this.deviceToken);
                    this._talkMeService.setUserID(this.deviceToken);
                });
        }
    }

    // При смене города
    switchCity() {
        this.deviceToken = this._deviceToken.get();
        this._yandexMetrika
            .getClientID()
            .pipe(take(1))
            .subscribe((id) => {
                this._api.authTelemetry.put({device_token: this.deviceToken, yandexmetrica_id: id});
            });
    }

    // При авторизации/регистрации
    auth() {
        this.deviceToken = this._deviceToken.get();
        this._yandexMetrika
            .getClientID()
            .pipe(take(1))
            .subscribe((id) => {
                this._api.authTelemetry.put({device_token: this.deviceToken, yandexmetrica_id: id});
            });
    }
}


