import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {BasketSmallComponent} from "./basket-small.component";
import {RouterModule} from "@angular/router";
import {BasketSmallListComponent} from "./list/list.component";
import {ImageModule} from "@shared/module/html/image/image.module";
import {SharedPipeModule} from "@shared/shared.pipe";
import {AngularSvgIconModule} from "angular-svg-icon";
import {FormatPricePipe} from "@shared/pipe/format-price";
import {ButtonGeneralModule} from "@component/button/general/general.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedPipeModule,
        ImageModule,
        AngularSvgIconModule,
        FormatPricePipe,
        NgOptimizedImage,
        ButtonGeneralModule,
    ],
    declarations: [
        BasketSmallComponent,
        BasketSmallListComponent,
    ],
    exports: [
        BasketSmallComponent
    ]
})
export class UiBasketSmallModule {
}
