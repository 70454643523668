import {inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GroupsFooterOutGet} from "./interface";
import {CityService} from "@core/service/city";

@Injectable()
export class GroupsFooterService {
    private _httpClient = inject(HttpClient);
    private _city = inject(CityService);

    /**
     *
     * @returns {Observable<GroupsFooterOutGet>}
     */
    get(): Observable<GroupsFooterOutGet> {
        const _obj = {
            city_id: this._city.data.id,
        };
        return this._httpClient.get<GroupsFooterOutGet>("ma/site/v1/groups/footer", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
