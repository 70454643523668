import {Injectable} from '@angular/core';
import {Cookie} from '../cookie';

@Injectable()
export class RegionsService {
    public id: number;

    constructor(private _cookie: Cookie) {
        this.id = this.get();
    }

    /**
     * Возращает текущий регион
     *
     * @returns {Object}
     */
    get(): number {
        try {
            return +this._cookie.getItem('region');
        } catch (e) {
            return 0;
        }
    }

    /**
     * Запись региона
     *
     */
    set(_id: number): void {
        this._cookie.setItem('region', _id.toString());
    }
}
