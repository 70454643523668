import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalkMeComponent } from "./talk-me.component";


@NgModule({
    declarations: [
        TalkMeComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        TalkMeComponent,
    ],
})
export class ButtonTalkMeModule {
}
