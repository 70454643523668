import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";
import {ProductsInGet, ProductsOutGet} from "./interface";

@Injectable()
export class ProductsService {
    constructor(private _httpClient: HttpClient,
                private _city: CityService) {
    }

    /**
     * Продукты
     *
     * @returns {Observable<ProductsOutGet>}
     */
    get(_obj: ProductsInGet): Observable<ProductsOutGet> {
        _obj["city_id"] = this._city.data.id;
        return this._httpClient.get<ProductsOutGet>("ma/site/v1/products", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
