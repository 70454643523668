import {inject, Injectable, OnDestroy} from "@angular/core";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {MetaCanonicalService} from "@common/core/service/meta/canonical.service";

@Injectable({
    providedIn: "root",
})
export class LoadCanonicalService implements OnDestroy {
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private metaCanonicalService = inject(MetaCanonicalService);
    private routerEventsEnd$ = new Subscription();
    private routerEventsStart$ = new Subscription();

    /**
     * Загрузка сервиса Canonical при инициализации сайта.
     * Отслеживает события роутера и при определенных условиях устанавливает тег canonical.
     * Используется для сервера и клиента.
     */
    public load() {
        this.routerEventsStart$ = this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.metaCanonicalService.remove();
        });

        this.routerEventsEnd$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
            // https://redmine.vigroup.ru/issues/56581
            if (queryParamMap.has("srsltid")) {
                const urlTree = this.router.parseUrl(this.router.url.toString());
                delete urlTree.queryParams["srsltid"];
                this.metaCanonicalService.set(urlTree.toString());
            }
        });

        return true;
    }

    ngOnDestroy() {
        this.routerEventsStart$.unsubscribe();
        this.routerEventsEnd$.unsubscribe();
    }
}
