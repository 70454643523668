import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../../request";
import {InPost, OutPost} from "./interface";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class SpecialsDetailService {

    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     *  #33522 Подписка из мегасовета
     *
     * @param {InPost} obj
     * @returns {Promise<OutPost>}
     */
    post(obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v1/specials/subscribe', obj)
            .then((res: Response) => res.json);
    }
}

