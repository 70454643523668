import {inject, Injectable} from "@angular/core";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {ActivatedRoute, Router} from "@angular/router";
import {CityService} from "@core/service/city";
import {skip} from "rxjs/operators";
import * as Sentry from "@sentry/angular";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {Cookie} from "@core/service/cookie";

@Injectable({
    providedIn: "root",
})
export class AppService {
    private deviceTokenService = inject(DeviceTokenService);
    private cookie = inject(Cookie);
    constructor(
        private _ya: YandexMetrikaService,
        private _router: Router,
        private _city: CityService,
        private _activatedRoute: ActivatedRoute,
    ) {
        this._city.city$.pipe(skip(1)).subscribe(() => {
            this.getSendCityYa();
        });
    }

    /**
     * Отправка изменение города в яндекс метрику
     */
    getSendCityYa() {
        let goal = "";
        const url = this._router.url;
        let paramMap;
        if (this._activatedRoute.snapshot.firstChild.firstChild.firstChild) {
            paramMap = this._activatedRoute.snapshot.firstChild.firstChild.firstChild.paramMap;
        } else if (this._activatedRoute.snapshot.firstChild.firstChild) {
            paramMap = this._activatedRoute.snapshot.firstChild.firstChild.paramMap;
        } else {
            paramMap = this._activatedRoute.snapshot.firstChild.paramMap;
        }

        if (url === "/basket") {
            // Сменил город в корзине - change_city_basket
            goal = "change_city_basket";
        } else if (url === "/basket/order" || url === "/basket/order/user") {
            // Сменил город на выборе аптек (шапка) - change_city_pharmacy
            goal = "change_city_pharmacy";
        } else if (url.indexOf("/search") !== -1) {
            // Сменил город на результатах поиска - change_search_result
            goal = "change_search_result";
        } else if (paramMap.get("item_code")) {
            // Сменил город на детальной товара - change_city_detail
            goal = "change_city_detail";
        } else if (paramMap.get("product_code")) {
            // Сменил город на странице продукта - change_city_product
            goal = "change_city_product";
        } else {
            // Сменил город (общая по сайту) - change_city
            goal = "change_city";
        }

        this._ya.reachGoal(goal);
    }

    setSentryTag() {
        Sentry.setTag("device-token", this.deviceTokenService.get());
        Sentry.setTag("state", this.cookie.getItem("state") || "not");
    }
}
