import {NgModule} from "@angular/core";
import {PhoneCursorDirective} from "./directive/phone-cursor";
import {CityLinkADirective} from "./directive/city-link-a";
import {YmReachGoalDirective} from "@shared/directive/yandex-metrica";

@NgModule({
    declarations: [
        PhoneCursorDirective,
        CityLinkADirective,
        YmReachGoalDirective
    ],
    exports: [
        PhoneCursorDirective,
        CityLinkADirective,
        YmReachGoalDirective
    ],
})
export class SharedDirectiveModule {
}
