<div class="smart-banner" *ngIf="show">
    <div class="smart-banner__icon">
        <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#a)">
                <path d="M36.0001 36h-36L0 0h36.0001v36Z" fill="url(#b)" />
                <path
                    d="M26.2083 12.7513a2.7462 2.7462 0 0 0-.1445-.4886v-.0412a2.7422 2.7422 0 0 0-.227-.4335v-.0551a2.8042 2.8042 0 0 0-.3234-.399 2.7857 2.7857 0 0 0-.3991-.3234h-.0481a2.753 2.753 0 0 0-.523-.3028h-.0344a2.7593 2.7593 0 0 0-.4885-.1445 2.7606 2.7606 0 0 0-.5092-.0619 2.7534 2.7534 0 0 0-1.0389.1995l-.1652.0826a2.7435 2.7435 0 0 0-.2958.1583c-.0757.0481-.1445.1169-.2202.172-.0757.055-.1307.1032-.1927.1582l-.0481.0344-3.5435 3.5229-3.5229-3.516-.0481-.0344c-.0551-.0482-.1101-.0894-.1652-.1376a2.753 2.753 0 0 0-.2408-.1927c-.0619-.0412-.1514-.0688-.1995-.11a2.7365 2.7365 0 0 0-.2615-.1377 2.7532 2.7532 0 0 0-2.0642 0 2.7503 2.7503 0 0 0-.4403.234h-.0413a2.7995 2.7995 0 0 0-.3922.3234 2.788 2.788 0 0 0-.4128.4541v.0482a2.7527 2.7527 0 0 0-.227.4334v.0413a2.7534 2.7534 0 0 0-.1446.4885 2.7532 2.7532 0 0 0-.0963.5505v7.8438a2.773 2.773 0 1 0 5.5457 0v-1.149l.2684.2683c.2358.4213.5806.7713.9983 1.0134a2.6893 2.6893 0 0 0 1.3755.3627h.1307a2.7032 2.7032 0 0 0 2.3738-1.3761l.2615-.2614v1.149a2.7728 2.7728 0 1 0 5.5457 0v-7.8507a2.752 2.752 0 0 0-.0413-.5229Zm-3.7086-.461a1.3936 1.3936 0 0 1 1.9713-.0035 1.3942 1.3942 0 0 1 .0034 1.9713l-3.7568 3.7568a2.7518 2.7518 0 0 0-.688-1.1559l-1.0459-1.0184 3.516-3.5503Zm-10.9608 0a1.4311 1.4311 0 0 1 1.9679 0l5.5457 5.5457a1.3914 1.3914 0 1 1-1.9678 1.9678l-5.5458-5.5457a1.3773 1.3773 0 0 1-.414-.9839 1.3766 1.3766 0 0 1 .414-.9839Zm2.3738 8.8277a1.376 1.376 0 1 1-2.7522 0v-5.2774l2.7522 2.7523v2.5251Zm9.5709 1.3761a1.3758 1.3758 0 0 1-1.3761-1.3761v-2.5251l2.7522-2.7523v5.3118a1.376 1.376 0 0 1-1.3486 1.3555l-.0275-.0138Z"
                    fill="#fff" />
                <path d="M24.75 36H36V24.75h-3.25c-4.4183 0-8 3.5817-8 8V36Z" fill="url(#c)" />
                <path
                    d="M29.2623 28.5246v-.7869c0-.3934.3443-.7377.7377-.7377s.7377.3443.7377.7377v.7869c0 .3934.3443.7377.7377.7377h.7869c.3934 0 .7377.3443.7377.7377s-.3443.7377-.7377.7377h-.7869c-.3934 0-.7377.3443-.7377.7377v.7869c0 .3934-.3443.7377-.7377.7377s-.7377-.3443-.7377-.7377v-.7869c0-.3934-.3443-.7377-.7377-.7377h-.7869c-.3934 0-.7377-.3443-.7377-.7377s.3443-.7377.7377-.7377h.7869c.3934-.0492.7377-.3443.7377-.7377Z"
                    fill="#E92428" />
            </g>
            <defs>
                <linearGradient id="b" x1="14.625" y1="16.8754" x2="36" y2="36.0004" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E92428" />
                    <stop offset="1" stop-color="#FE6D6D" />
                </linearGradient>
                <linearGradient id="c" x1="31.875" y1="33.375" x2="27" y2="25.875" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEE0E0" />
                    <stop offset="1" stop-color="#fff" />
                </linearGradient>
                <clipPath id="a">
                    <rect width="36" height="36" rx="7" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    </div>
    <div class="smart-banner__comp">Мегаптека.ру</div>
    <a class="link link_underline smart-banner__link" target="_blank" href="https://redirect.appmetrica.yandex.com/serve/890937166409058766?source=red_button">Установить</a>
    <div class="smart-banner__text">Бесплатное приложение</div>
    <div class="smart-banner__close ma-svg-close" (click)="onClose()"></div>
</div>
