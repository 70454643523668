import {APP_INITIALIZER} from "@angular/core";
import {LoadCanonicalService} from "@common/core/load/canonical.service";

/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const APP_INITIALIZER_GENERAL = [
    {
        provide: APP_INITIALIZER,
        useFactory: (loadCanonicalService: LoadCanonicalService) => () => loadCanonicalService.load(),
        deps: [LoadCanonicalService],
        multi: true,
    },
];
