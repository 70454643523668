import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "colorRandom",
})
export class ColorRandomPipe implements PipeTransform {
    private colorList: string[] = [
        "#B0D267",
        "#96CEE0",
        "#E8CA7A",
        "#6058CA",
        "#C8A0DC",
    ];
    transform(index: number): string {
        const i = index % this.colorList.length;
        return this.colorList[i];
    }

}
