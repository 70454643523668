import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";
import {OrdersDialogOutGet} from "@shared/service/api/orders/dialog/dialog";

@Injectable({
    providedIn: "any",
})
export class OrdersDialogService {
    constructor(
        private _httpClient: HttpClient,
        private _city: CityService,
    ) {}

    /**
     * Рекомендуемые товары в корзине
     *
     * @returns {Observable<OrdersDialogOutGet>}
     */
    get(): Observable<OrdersDialogOutGet> {
        const _obj = {
            city_id: this._city.data.id,
        };
        return this._httpClient.get<OrdersDialogOutGet>("ma/site/v5/orders/dialog", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
