import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InPost, OutPost} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class OrdersCashbackService {

    constructor(protected _request: ApiRequest) {
    }

    /**
     * Отправка кэшбэка
     *
     * @param _obj
     */
    post(_obj ?: InPost): Promise<OutPost> {
              return this._request
            .create('ma/site/v4/orders/cashback', _obj)
            .then((res: Response) => res.json);
    }
}

