import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ScrollTopComponent} from "./scroll-top.component";
import {SvgArrowUpModule} from "@shared/module_v2/svg/arrow-up/arrow-up.module";


@NgModule({
    declarations: [
        ScrollTopComponent,
    ],
    imports: [
        CommonModule,
        SvgArrowUpModule,
    ],
    exports: [
        ScrollTopComponent,
    ],
})
export class UiScrollTopModule {
}
