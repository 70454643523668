/* Service */
import {NgModule} from '@angular/core';
import {GoogleAnalyticsService} from '@core/module/google/analytics';

@NgModule({
    declarations: [],
    providers: [
        GoogleAnalyticsService,
    ],
    exports: []
})

export class GoogleModule {
}
