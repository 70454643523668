import {Directive, ElementRef, HostListener} from "@angular/core";

/**
 * @deprecated
 */
@Directive({
    selector: '[maPhoneCursor]'
})

export class PhoneCursorDirective {
    private phoneTemplate = '+7 (___) ___ ____';
    private input: any;

    constructor(private el: ElementRef) {
        this.input = this.el.nativeElement;
    }

    @HostListener('keyup', ['$event']) onKeyPress(event: KeyboardEvent) {
        if (event.keyCode === 8 || event.keyCode === 46) {
            if (!this.input.value || this.input.value === this.phoneTemplate ) {
                this.input.value = this.phoneTemplate;
                this.input.setSelectionRange(4, 4);
                this.input.focus();
            }
        }
    }

    @HostListener('focus')
        onInputChange() {
            this.cursorEnd();
        }

    @HostListener('click')
        onInputClick() {
            this.cursorEnd();
        }

    cursorEnd() {
        if (!this.input.value) {
            this.input.value  = this.phoneTemplate;
        }
        if (this.input.setSelectionRange) {
            const posSearch = this.input.value.search(/_/i);
            let pos = this.input.value.length;

            if (posSearch > 0) {
                pos = posSearch;
            }
            this.input.setSelectionRange(pos, pos);
            this.input.focus();
        }
    }
}
