import {inject, Injectable} from "@angular/core";
import {Cookie} from "@core/service/cookie";

@Injectable({
    providedIn: "root",
})
export class SmartBannerService {
    private cookie = inject(Cookie);
    private readonly NAME_STORAGE_SMART_BANNER: string = "ma_close_smart_banner";
    private status = "";

    public load() {
        this.status = this.cookie.getItem(this.NAME_STORAGE_SMART_BANNER);
    }

    public getStatus(): boolean {
        return this.status === "1";
    }

    public setStatus() {
        this.cookie.setItem(this.NAME_STORAGE_SMART_BANNER, "1");
    }
}
