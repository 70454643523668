import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../../request";
import {InGet, OutGet} from "./interface";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class SpecialsItemsService {

    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     * Товары на детальной публикации
     *
     * @param {InGet} obj
     * @returns Promise<OutGet>
     */
    get(obj: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/specials/items', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }
}

