import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouterLink} from "@angular/router";

@Component({
    standalone: true,
    selector: "app-politics",
    templateUrl: "./politics.component.html",
    styleUrls: ["./politics.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
    ],
})
export class PoliticsComponent {

}
