import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TgComponent} from './tg.component';


@NgModule({
  declarations: [
    TgComponent
  ],
  imports: [
    CommonModule
  ], exports: [TgComponent]
})
export class SvgTgModule { }
