import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../request";
import {InGet, OutGet} from "./interface";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class CommentsService {

    constructor(private _request: ApiRequest, private _apiUtilsService: ApiUtilsService) {
    }

    /**
     *
     * @return {Promise<OutGet>}
     */
    get(obj: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/comments', obj)
            .then((res: Response) => res.json);
    }

}




