<div class="main-menu-catalog-drop down">
    <div class="main-menu-catalog-drop-step1">
        <div *ngFor="let group of groups" (mouseenter)="step2Up(group)">
            <a maLinkCity class="main-menu-catalog-drop-step1-elem" [class.active]="activeTab.code === group.code"
               [routerLink]="['/', 'catalog', group.code]">{{ group.name }}</a>
        </div>
    </div>
    <div class="main-menu-catalog-drop-step2"
         [style.column-count]="activeTab.children | columnCount"
         *ngIf="activeTab && activeTab.children && activeTab.children.length > 0">
        <a maLinkCity
           class="main-menu-catalog-drop-step2-elem"
           *ngFor="let elem of activeTab.children; trackBy: trackByFn"
           [routerLink]="['/', 'catalog', elem.code]">{{ elem.name }}</a>
    </div>
</div>
