import {DialogConfig} from "@angular/cdk/dialog";
import {ElemOrder} from "@common/shared/model/elem-order";

export class DialogActiveOrdersDesktop {
    data: DialogActiveOrdersData;
    options: DialogConfig = {
        panelClass: "active-orders-dialog",
        closeOnNavigation: true,
        width: "1108px",
        autoFocus: false,
    };
}
export class DialogActiveOrdersMobile {
    data: DialogActiveOrdersData;
    options: DialogConfig = {
        panelClass: "active-orders-dialog",
        closeOnNavigation: true,
        autoFocus: false,
        hasBackdrop: true,
        backdropClass: "backdrop",
    };
}
export type DialogActiveOrdersData = ElemOrder;
