import {Pipe, PipeTransform} from "@angular/core";
import {TextService} from "@shared/service/text";

@Pipe({
    name: 'timeSeconds'
})
export class TimeSecondsPipe implements PipeTransform {
    constructor(private _text: TextService) {

    }

    transform(_time: number): string {
        const minute = Math.ceil(_time / 60);
        let buildTime = '';
        if (minute > 60) {
            const hour = Math.ceil(minute / 60);
            if (hour > 24) {
                const day = Math.ceil(hour / 24);
                buildTime = day + ' ' + this._text.transform(day, ['д.', 'д.', 'д.']);
            } else {
                buildTime = hour + ' ' + this._text.transform(hour, ['ч.', 'ч.', 'ч.']);
            }
        } else {
            buildTime = minute + ' ' + this._text.transform(minute, ['мин.', 'мин.', 'мин.']);
        }

        return buildTime;
    }
}
