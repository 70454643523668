import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CityLinkComponent} from "./city-link.component";
import {SharedPipeModule} from "@shared/shared.pipe";
import {SvgPlaceModule} from "@shared/module_v2/svg/place/place.module";
import {SvgMapModule} from "@shared/module_v2/svg/map/map.module";


@NgModule({
    declarations: [CityLinkComponent],
    imports: [
        CommonModule,
        SharedPipeModule,
        SvgPlaceModule,
        SvgMapModule
    ],
    exports: [CityLinkComponent]
})
export class ButtonCityLinkModule {
}
