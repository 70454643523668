import {DialogConfig} from "@angular/cdk/dialog";

export const DIALOG_UI_CITY_SELECTION_CONFIG: DialogConfig = {
    maxWidth: "700px",
    width: "100%",
    maxHeight: "620px",
    height: "95%",
    closeOnNavigation: true,
    panelClass: 'dialog-city-selection',
};
