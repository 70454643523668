import {Inject, Injectable} from "@angular/core";
import {CookieInject} from "./inject";

@Injectable({
    providedIn: "root"
})
export class Cookie implements Storage {

    [index: number]: string;
    [key: string]: any;
    length: number;
    cookies: any;

    constructor(@Inject(CookieInject) private _cookie: any) {
    }

    clear(): void {
        this._cookie.clear();
    }

    getItem(key: string): string {
        return this._cookie.getItem(key);
    }

    key(index: number): string {
        return this._cookie.key(index);
    }

    removeItem(key: string): void {
        this._cookie.removeItem(key);
    }

    setItem(key: string, value: string, domain?: string, expires?: number): void {
        this._cookie.setItem(key, value, domain, expires);
    }
}
