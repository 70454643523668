import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {CityService} from "@core/service/city";
import {filter} from "rxjs/operators";
import {LayoutHeaderFloatingService} from "@shared/module_v2/layout/header-floating/header-floating.service";

@Component({
    selector: "ma-layout-header-floating",
    templateUrl: "./header-floating.component.html",
    styleUrls: ["header-floating.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HeaderFloatingComponent implements OnInit {
    private _isViewStatic: boolean;
    get isViewStatic(): boolean {
        return this._isViewStatic;
    }

    @Input() set isViewStatic(value: boolean) {
        this._isViewStatic = value;
        this.eventWindowScroll();
    }

    public city: any;

    @HostBinding("class.visible") visible: boolean = false;

    @HostListener("window:scroll") windowScroll() {
        this.eventWindowScroll();
    }

    @ViewChild("dynamic", {read: ViewContainerRef}) private viewRef: ViewContainerRef;
    private componentRef: ComponentRef<any>;

    constructor(@Inject(DOCUMENT) private document: Document,
                private _cdr: ChangeDetectorRef,
                private _layoutHeaderFloatingService: LayoutHeaderFloatingService,
                private _cityService: CityService) {
    }

    ngOnInit() {
        this._cityService.city$.subscribe((res) => {
            this.city = res;
        });

        this._layoutHeaderFloatingService.eventRef()
            .pipe(filter(res => res !== null))
            .subscribe((res) => {
                if (this.viewRef) {
                    this.viewRef.clear();
                }

                if (res.length > 0) {
                    res.map((v) => {
                        this.create(v);
                    });
                }
                this._cdr.detectChanges();
            });
    }

    create(res) {
        this.componentRef = this.viewRef.createComponent(res.comp);
        if (res.data) {
            for (const prop in res.data) {
                // eslint-disable-next-line no-prototype-builtins
                if (!res.data.hasOwnProperty(prop)) {
                    continue;
                }
                this.componentRef.instance[prop] = res.data[prop];
            }
        }
    }

    eventWindowScroll() {
        const pos = 80;
        const number = window.pageYOffset
            || document.documentElement.scrollTop
            || document.body.scrollTop || 0;

        this.visible = number > pos;
        if (this.isViewStatic) {
            this.visible = true;
        }

        this._cdr.detectChanges();
    }
}
