import {Config} from "@shared/model/config";

export const environment: Config = {
    production: false,
    api: {
        url: "api.megapteka.dev",
        urlReserve: "proverka-api.megapteka.dev",
        port: "",
        protocol: "https",
        secret: "66DtPBSYVh98NhBpVJEzEAnqju02DbadkVhyP9AzD8VgmrAonbzs2yrOKjUiEDcU",
        ncp: true,
        header: {
            platform: 4,
            stock: true,
            build: import.meta.env.NG_APP_BUILD || "",
        },
    },
    pay: {
        publicId: "pk_cee1791e562cc8a7218bf051398f2",
    },
    debug: false,
    site: {
        domain: "Megapteka.ru",
        host: "https://megapteka.dev",
        title: "",
        date: {
            full: "DD.MM.YYYY HH:mm",
            time: "HH:mm",
            date: "DD.MM.YYYY",
        },
    },
    shop: {
        textBuyBtn: "В корзину",
    },
    ya: {
        id: 54810277,
        id_maps: "4ac709f1-9c6f-42b5-85dd-a192a558bf06",
        id_suggest: "b5a3c609-c96b-4039-9628-62d777962cf2",
        version: 2, // 1 || 2
        actionField: 37361321,
        goal: {
            url: [
                {url: "/basket", number: 37360946},
                {url: "/basket/order", number: 37361273},
                {url: "/basket/success", number: 37361321},
            ],
            identifier: [
                {code: "toDetail", number: 37361345},
                {code: "toBasketFromCard", number: 37361360},
                {code: "toBasketFromDetail", number: 37361369},
                {code: "removeFromBasket", number: 37361378},
                {code: "fillingForm", number: 42867473},
                {code: "mpLandingClickGP", number: 48329008},
                {code: "mpLandingClickAS", number: 48329017},
                {code: "mpLandingFillingForm", number: 48329026},
                {code: "downloadPresentation", number: 48048121},
                {code: "checkOrder", number: 54093439},
                {code: "whyPriceChanged", number: 57235510},
                {code: "antikleshchPay", number: 81025858},
                {code: "31395_goToCart", number: 88815721},
                {code: "31395_continueShopping", number: 88815916},
                {code: "antikleshchCalculate", number: 91863070},
                {code: "mpLandingFillingForm", number: 46976325},
                {code: "Subsr_item", number: 46976325},
                {code: "changeLink", number: 46976325},
                {code: "changeButton", number: 46976325},
                {code: "button_1click", number: 46976325},
                {code: "store_1click", number: 46976325},
                {code: "order_1click", number: 46976325},
                {code: "item_map", number: 46976325},
                {code: "recomend_cart", number: 46976325},
                {code: "pharmacy_send_lead_form", number: 186967069},
                {code: "specials_sharing_vk", number: 213820876},
                {code: "specials_sharing_ok", number: 213820930},
                {code: "specials_sharing_fb", number: 213824014},
                {code: "specials_sharing_tw", number: 213824038},
                {code: "specials_subscribe_ok", number: 214061434},
                {code: "specials_subscribe_vk", number: 214061449},
                {code: "specials_subscribe_fb", number: 214061461},
                {code: "specials_subscribe_ig", number: 214061479},
            ],
        },
    },
    google: {
        id: "UA-111090516-1",
        optimize_id: "GTM-NML9LVJ",
        metrika: {
            counter: {
                list: [{url: "/basket"}, {url: "/basket/success"}, {url: "/basket/order"}],
            },
        },
    },
    mail: {
        id: "2948406",
    },
    sentry: {
        release: import.meta.env.NG_APP_BUILD || "",
        dsn: "https://a8e43689f99c491a30e054fe1b5bf799@sentry.megapteka.ru/60",
        tracingOrigins: ["localhost", "https://megapteka.dev"],
        sampleRate: 0,
        tracesSampleRate: 0,
        ignoreErrors: [
            /.*ERR_HTTP_HEADERS_SENT.*/,
            "Error [ERR_HTTP_HEADERS_SENT]: Cannot set headers after they are sent to the client",
            "Error: Cannot set headers after they are sent to the client",
            "Cannot set headers after they are sent to the client",
            /.*Handled unknown error.*/,
            "VAS Error: play request without AdMedia",
            "AbortError: The play()",
            "NotSupportedError: The element has no supported sources.",
            "Can't find variable: MediaSource",
        ],
        denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
    },
    cackle: {
        id: 78962,
    },
    telegram: {
        bot_subscribe: "megaptekadev_bot",
    },
    gis: {
        key: "048f5872-c73b-4103-b14a-88ae087dad81",
    },
};
