import {
    ComponentFactoryResolver,
    ComponentRef,
    createNgModuleRef,
    Injectable,
    Injector,
    NgModuleRef,
    Type,
    ViewContainerRef,
} from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LazyLoadedService {

    constructor(private cfr: ComponentFactoryResolver, private injector: Injector) {
    }

    /**
     * Ленивая загрузка модуля
     *
     * @param host
     * @param importModule
     */
    public async module<T>(host: ViewContainerRef, importModule: Promise<any>): Promise<ComponentRef<T>> {

        return importModule
            .then((res) => {
                const module: Type<T> = (<any>res)[Object.keys(res)[0]];
                const component: any = (res)[Object.keys(res)[0]].components.lazyComponent;

                const moduleRef: NgModuleRef<T> = createNgModuleRef(module, this.injector);

                return host.createComponent(component, {
                    ngModuleRef: moduleRef,
                    injector: this.injector,
                });
            });
    }
}
