import {Injectable} from "@angular/core";
import * as SHA256 from "crypto-js/sha256";
import * as WordArray from "crypto-js/lib-typedarrays";
import {Cookie} from "@core/service/cookie";
import {LsService} from "@core/service/localstorage";
import {EMPTY} from "rxjs";
import {Platform} from "@core/service/platform";

@Injectable({
    providedIn: 'root'
})
export class DeviceTokenService {
    private readonly nameStorage: string = 'device_token';
    private deviceToken: string = '';

    constructor(private _cookie: Cookie, private _ls: LsService, private _platform: Platform) {
    }

    /**
     * Установка девайс токена пользователя
     */
    set() {
        const token: string = this.get();
        if (!token) {

            const oldToken = this._ls.get(this.nameStorage);
            if (oldToken) {
                // Записываем из localStorage в Cookie
                this.deviceToken = oldToken;
                this._cookie.setItem(this.nameStorage, this.deviceToken);

                return EMPTY;
            }else {
                // Создаем новый токен если нет старого в localStorage и Cookie
                const string: string = WordArray.random(128/8).words.join('');
                const time: string = Date.now().toString();

                this.deviceToken = <string>SHA256(string + time).toString().toUpperCase();
                this._cookie.setItem(this.nameStorage, this.deviceToken);
            }

        } else {
            // Обновляем токен по времени
            this.deviceToken = token;
            this._cookie.setItem(this.nameStorage, token);
        }

        return EMPTY;
    }

    /**
     * Получение девайс токена пользователя
     */
    get(): string {
        return this._cookie.getItem(this.nameStorage) || this.deviceToken;
    }
}
