import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouterLink} from "@angular/router";

@Component({
    standalone: true,
    selector: 'app-recommendation-technologies',
    templateUrl: './recommendation-technologies.component.html',
    styleUrls: ['./recommendation-technologies.component.less'],
    imports: [
        RouterLink
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationTechnologiesComponent {}
