import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InPost, OutPost} from "./interface";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class ItemsSubscribeService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     * @param {InPost} obj
     * @returns {Promise<OutPost>}
     */
    post(obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v3/items/subscribe', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }

}

