import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoMaComponent} from './logo-ma.component';


@NgModule({
    declarations: [
        LogoMaComponent
    ],
    imports: [
        CommonModule
    ], exports: [
        LogoMaComponent
    ]
})
export class SvgLogoMaModule {
}
