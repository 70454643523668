import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter} from "rxjs/operators";
import {Subscription} from "rxjs/internal/Subscription";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

export interface SearchParams {
    loading?: boolean;
    focus?: boolean;
}

@Component({
    selector: 'ma-input-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy, DoCheck {

    /**
     * placeholder, по умолчанию пустой
     */
    @Input() placeholder: string = '';

    /**
     *  Время задержки возращение value. Значение в мс. По умолчанию 500 мс.
     */
    @Input() debounceTime: number = 500;

    /**
     * Занчение
     */
    @Input() set value(val) {
        this.form.get('input').setValue(val);
    }

    /**
     * Введеное значение EventEmitter
     */
    @Output() onValue = new EventEmitter<string>();

    @Output() onFocus = new EventEmitter<FocusEvent>();

    @Output() onKeyEnter = new EventEmitter<string>();

    @Input() params: SearchParams = {loading: false, focus: true};

    @Input() loading = false;

    @ViewChild('inputRef') inputRef: ElementRef;

    public form = new UntypedFormGroup({
        input: new UntypedFormControl()
    });
    private valueChanges$: Subscription;
    private inputSendValue$: Subscription;
    private onKey$: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(private _cdr: ChangeDetectorRef) {
    }


    ngDoCheck() {
        this._cdr.detectChanges();
    }

    ngOnInit(): void {
        this.valueChanges$ = this.form.valueChanges.subscribe(() => {
            this._cdr.detectChanges();
        });

        this.inputSendValue$ = this.onKey$
            .pipe(filter(res => res !== null),
                distinctUntilChanged(),
                debounceTime(this.debounceTime),
            )
            .subscribe((res) => {
                this.onValue.emit(res);
            });
    }

    ngOnDestroy() {
        if (this.valueChanges$) {
            this.valueChanges$.unsubscribe();
        }
        if (this.inputSendValue$) {
            this.inputSendValue$.unsubscribe();
        }
    }

    /**
     * Отправка поля по кнопки
     */
    onClickSend(): void {
        this.onValue.emit(this.form.get('input').value);
    }

    /**
     * Очистить поле
     */
    onClickClose(): void {
        this.form.get('input').setValue('');
        this.onValue.emit('');
        this._cdr.detectChanges();
    }

    onKey() {
        this.onKey$.next(this.form.get('input').value);
    }

    onFocusInput($event: FocusEvent) {
        if (this.params.focus === false) {
            this.inputRef.nativeElement.blur();
            this.onFocus.emit($event);
        }
    }
}
