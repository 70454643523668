import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@Component({
    selector: 'app-input-code',
    templateUrl: './component.html'
})
export class InputCodeComponent implements OnInit {
    @Input() control: UntypedFormControl;
    @Input() form: UntypedFormGroup;
    @Input() focus: boolean = false;
    @Input() submit: boolean = false;
    @Input() placeholder: string = 'Номер заказа';
    @Input() inputClass: string = 'form8';
    @Input() setError: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('code') elemCode: ElementRef;

    public name: string = 'code';

    ngOnInit() {
        if (this.focus) {
            this.elemCode.nativeElement.focus();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
