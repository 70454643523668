import {DialogConfig} from "@angular/cdk/dialog";

export const DIALOG_UI_MENU_MOBILE_CONFIG: DialogConfig = {
    panelClass: ["dialog-menu-mobile"],
    closeOnNavigation: true,
    hasBackdrop: false,
    maxWidth: "100vw",
    width: "100vw",
    maxHeight: "100%",
    height: "calc(100% - 50px)",
};
