import {Injectable} from "@angular/core";
import {ApiRequest, Response} from "./../../request";
import {InGet, OutGet} from "./interface";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class GroupsService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     *
     * @param {InPost} obj
     * @returns {Promise<OutPost>}
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v2/groups', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }

}

