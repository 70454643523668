import {Inject, Injectable, Injector, makeStateKey, TransferState} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import forEach from "lodash-es/forEach";

import {CookieService} from "ngx-cookie-service";
import * as HmacSHA256 from "crypto-js/hmac-sha256";
import {Config} from "../config";
import {LogService} from "../logger";
import {Platform} from "../platform";
import {REQUEST} from "@common/tokens/express.tokens";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {firstValueFrom} from "rxjs";

export interface Response {
    status: number;
    json: any;
}

@Injectable()
export class ApiRequest {
    protected data: any = {};
    protected json: string;

    constructor(private _http: HttpClient,
                private _config: Config,
                @Inject(REQUEST) private request: any,
                private _log: LogService,
                private _transferState: TransferState,
                private _cookieService: CookieService,
                private _deviceTokenService: DeviceTokenService,
                @Inject(Injector) public _injector: Injector,
                private _platform: Platform) {
    }

    /**
     *
     *
     * @param {string} _path
     * @param {Object} _data
     * @param {string[]} _files
     * @param {boolean} _isServer
     * @return {Promise<Response>}
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async query(_path: string, _data: object = {}, _files: string[] = [], _isServer: boolean = true) {
        this.params(_data);

        const json: string = this.json;

        const urlObj = new URL(this.createUrl(_path));
        if (json !== '') {
            urlObj.searchParams.set('data', json);
            urlObj.searchParams.set("ngsw-bypass", "true");
        }
        const url: string = urlObj.toString();
        this._log.debug("GET", url);

        const key = makeStateKey(_path + json);
        const data = this.resolveData(key);

        if (this._platform.browser && data) {
            this.delCache(key);
        }
        if (data && _isServer) {
            return new Promise((resolve) => {
                resolve(this.reply(data));
            });
        } else {
            const time = new Date().getTime();
            return await firstValueFrom(this._http.get(url, {headers: this.header()}))
                .then((body: any) => {
                    if (this._platform.server) {

                        // log
                        const logs = {
                            url: _path,
                            request: JSON.stringify(_data),
                            response: JSON.stringify(body),
                            time: ((new Date().getTime() - time) / 1000).toFixed(2),
                        };
                        this._log.debug(`${new Date().toISOString()} - Время запроса:`, logs);
                        // console.log(`${new Date().toISOString()} - Время запроса:`, logs);
                        this.setCache(key, body);
                    }

                    return this.reply(body);
                });
        }
    }

    /**
     * Обновления
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public update(_path: string, _data: object = {}) {
        this.params(_data);

        const url: string = this.createUrl(_path);
        this._log.debug("PUT", url);
        return this._http
            .put(url, this.json, {headers: this.header()})
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);

            });
    }

    /**
     * Создания
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public create(_path: string, _data: object = {}) {
        this.params(_data);

        const url: string = this.createUrl(_path);
        this._log.debug("POST", url);
        return this._http
            .post(url, this.json, {headers: this.header()})
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);
            });
    }

    /**
     * Возращает отформатированный ответ из запроса
     *
     * @param {Response} res
     * @return {ApiResponse}
     */
    private reply(res: Response) {
        const reply = {
            json: res,
        };
        return <Response>reply;
    }

    /**
     * Возращает отформатированный ответ ошибки
     *
     * @param {HttpErrorResponse} res
     *
     * @return {}
     */
    private replyError(res: HttpErrorResponse) {

        const reply = {
            message: res.error.message,
            status: res.status,
            action: res.error.action,
        };

        return <{message: string; status: number; action: string}>reply;
    }


    /**
     * Создания url
     *
     * @param path
     * @returns {string}
     */
    protected createUrl(path: string): string {
        let urlPath = "";
        const api = this._config.get("api");
        const port = api["port"];
        const protocol = api["protocol"];
        const host = api["url"];
        const queryParams = api['queryParams'] || null;

        urlPath += protocol + "://";
        urlPath += host;
        if (port) {
            urlPath += ":" + port;
        }
        urlPath += "/" + path;

        const url = new URL(urlPath);

        if (Array.isArray(queryParams)) {
            queryParams.forEach((item) => {
                url.searchParams.set(item.key, item.value);
            });
        }

        return url.toString();
    }

    /**
     * Возращает header
     *
     * @param params
     * @returns {Headers}
     */
    protected header(params: {} = {}): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({});
        const configHeader = this._config.get("api")["header"];

        /**
         * Конфиг
         */
        forEach(configHeader, function(val, key) {
            headers = headers.append(key.toString(), val.toString());
        });

        /**
         * Входящие параметры
         */
        if (params) {
            forEach(params, function(val: any, key: any) {
                headers = headers.set(<string>key, <string>val);
            });
        }

        /**
         * Токен
         */
        headers = headers.append("token", this.createToken());

        headers = headers.append('Content-Type', 'application/json');

        if (this._platform.browser) {
            const authToken = (this._cookieService.get("auth_token")) ? this._cookieService.get("auth_token") : "";
            if (authToken.length > 0) {
                headers = headers.append("auth_token", authToken);
            }
        }
        headers = headers.append("Device-Token", this._deviceTokenService.get());

        if (this._platform.server) {
            headers = headers.append("x-forwarded-for", this.request["header"]["x-forwarded-for"]);

            if (this.request["headers"]["ma-action-id"]) {
                headers = headers.append("ma-action-id", this.request["headers"]["ma-action-id"]);
            }
            if (this.request["headers"]["x-qrator-ip-source"]) {
                headers = headers.append("x-qrator-ip-source", this.request["headers"]["x-qrator-ip-source"]);
            }
        }
        return headers;
    }


    /**
     * Подготовка данных
     *
     * @param _data обьект с данными
     *
     * @returns {string}
     */
    protected params(_data: object): void {
        this.data = _data;
        this.json = <string>JSON.stringify(this.data);
    }

    /**
     * Создания токен
     */
    protected createToken(): string {

        const api = this._config.get("api");
        const secret = <string>api["secret"];
        const dataJson = JSON.stringify(this.data);

        const token = <string>HmacSHA256(dataJson, secret)
            .toString()
            .toUpperCase();

        this._log.debug("Token data", this.data);
        this._log.debug("Token json", dataJson);
        this._log.debug("Token", token);

        return token;
    }

    private resolveData(key: string) {
        const data = this.getFromCache(key);

        if (!data) {
            return false;
        }
        return data;
    }

    private setCache(key, data) {
        this._transferState.set(key, data);
        return data;
    }

    private getFromCache(key): any {
        return this._transferState.get(key, null);
    }

    private delCache(key) {
        this._transferState.remove(key);
    }
}
