import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../request";
import {InGet, OutGet} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class MaIndexService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Индекс Мегаптеки
     *
     * @param {InGet} obj
     * @returns Promise<OutGet>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/ma-index', obj)
            .then((res: Response) => res.json);
    }
}

