import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";
import {SearchQueriesInGet, SearchQueriesOutGet} from "./interface";

@Injectable({
    providedIn: "any"
})
export class SearchQueriesService {
    constructor(private _httpClient: HttpClient,
                private _city: CityService) {
    }

    /**
     *
     * @returns {Observable<SearchQueriesOutGet>}
     */
    get(_obj: SearchQueriesInGet): Observable<SearchQueriesOutGet> {
        return this._httpClient.get<SearchQueriesOutGet>("ma/site/v3/search/queries", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
