import {inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GroupsMenuOutGet} from "./interface";
import {CityService} from "@core/service/city";

@Injectable()
export class GroupsMenuService {
    private _httpClient = inject(HttpClient);
    private _city = inject(CityService);

    /**
     *
     * @returns {Observable<GroupsMenuOutGet>}
     */
    get(): Observable<GroupsMenuOutGet> {
        const _obj = {
            city_id: this._city.data.id,
        };
        return this._httpClient.get<GroupsMenuOutGet>("ma/site/v1/groups/menu", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
