import {Injectable} from '@angular/core';
import {Platform} from '@core/service/platform';
import {MobileService} from '@core/service/mobile';
import {BreakpointObserver} from '@angular/cdk/layout';
import {EMPTY} from 'rxjs/internal/observable/empty';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

interface IResize {
    mobile: boolean;
    desktop: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ResizeService {
    public resize$: BehaviorSubject<IResize> = new BehaviorSubject({mobile: true, desktop: false});

    private breakpointParams: string[] = [
        '(max-width: 1000px)'
    ];

    constructor(private _platform: Platform,
                private _mobileService: MobileService,
                private _breakpointObserver: BreakpointObserver) {
    }


    public load() {
        if (this._platform.server) {
            if (this._mobileService.isMobile()) {
                this.resize$.next({desktop: false, mobile: true});
            } else {
                this.resize$.next({desktop: true, mobile: false});
            }
        } else {
            this._breakpointObserver
                .observe(this.breakpointParams).subscribe(result => {
                if (result.matches === true) {
                    this.resize$.next({desktop: false, mobile: true});
                } else {
                    this.resize$.next({desktop: true, mobile: false});
                }
            });
        }

        return EMPTY;
    }

}
