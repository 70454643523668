import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class Storage {
    private nameUserId = 'epn_user_id';
    private nameClientId = 'epn_click_id';
    private date = new Date(Date.now() + 3600 * 24 * 1000 * 30);

    constructor(protected _cookie: CookieService) {

    }

    /**
     * Запись значение в хранилище user-id
     *
     * @param {string} value
     */
    protected setUserId(value: string) {
        return this._cookie.set(this.nameUserId, value, this.date, '/');
    }

    /**
     * Возращает значение из хранилище user-id
     *
     * @returns {number[]}
     */
    protected getUserId(): string {
        try {
            return this._cookie.get(this.nameUserId);
        } catch (e) {
            return '';
        }

    }

    /**
     * Запись значение в хранилище click-id
     *
     * @param {string} value
     */
    protected setClickId(value: string) {
        return this._cookie.set(this.nameClientId, value, this.date, '/');
    }

    /**
     * Возращает значение из хранилище click-id
     *
     * @returns {number[]}
     */
    protected getClickId(): string {
        try {
            return this._cookie.get(this.nameClientId);
        } catch (e) {
            return '';
        }

    }
}
