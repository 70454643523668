import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../request";
import {InGet, InPost, OutGet, OutPost} from "./interface";
import {Response} from "@core/service/api2/request";

@Injectable()
export class ArticlesService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Список Публикаций
     *
     * @param {InGet} obj
     * @returns Promise<OutGet>
     */
    get(obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/articles', obj)
            .then((res: Response) => res.json);
    }

    /**
     * Подписаться на публикации
     *
     * @param {InPost} obj
     * @returns {Promise<OutPost>}
     */
    post(obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v1/subscribe/publications', obj)
            .then((res: Response) => res.json);
    }
}

