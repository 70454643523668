import {Directive, HostBinding, Inject, OnInit} from "@angular/core";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {CityService} from "@core/service/city";
import {ORIGIN_HREF} from "@shared/injection-token/tokens";
import {REQUEST} from "@common/tokens/express.tokens";

/**
 * @deprecated use maLinkCity
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[appCityLinkA], .app-a-city-link, [maCityLinkA]'})

export class CityLinkADirective implements OnInit {
    @HostBinding('attr.href') hrefAttr = '';
    constructor(private link: RouterLink,
                private route: ActivatedRoute,
                @Inject(ORIGIN_HREF) public _baseHref: string,
                @Inject(REQUEST) public _request: any,
                private _city: CityService) {
    }

    public ngOnInit(): void {
        const city = this._city.data;
        const href = this.link.href;
        this.hrefAttr = href;
        const hrefArr = [];
        if (href) {
            href.split('/').forEach((v) => {
                if (v) {
                    const val = v.replace(/#.*/ig, ''); // delete  hash tag
                    hrefArr.push(decodeURI(val));
                }
            });

            if (city.code && city.code !== 'undefined') {

                const url = `/${city.code}/${hrefArr.join('/')}`;
                this.link.href = url.toString();
                this.link.routerLink = url.toString();
                this.hrefAttr = url.toString();
            } else {
                console.error(city);
            }
        } else {
            console.error(href);
        }

    }
}
