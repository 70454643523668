import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";

/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const CONFIG_CORE_BROWSER = [
    {
        provide: Sentry.TraceService,
        deps: [Router],
    },
];
