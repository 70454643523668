<form [formGroup]="form" class="search" action=".">
    <input
        #inputRef
        formControlName="input"
        type="search"
        placeholder="{{ placeholder }}"
        (keyup)="onKey()"
        (keydown.enter)="onKeyEnter.emit(form.get('input').value)"
        (focus)="onFocusInput($event)"
        autocomplete="off"
        name="search" />
    <div class="search__icon ma-svg-magnifier" (click)="onClickSend()"></div>
    <div class="search__dot ma-svg-dots"></div>
    <svg-icon
        class="search__close"
        [class.active]="params.loading || loading"
        *ngIf="form.get('input').value"
        (click)="onClickClose()"
        [src]="'icons/svg/close.svg'"></svg-icon>
</form>
