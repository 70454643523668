import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MedalComponent} from './medal.component';


@NgModule({
  declarations: [
    MedalComponent
  ],
  imports: [
    CommonModule
  ], exports: [MedalComponent]
})
export class SvgMedalModule { }
