import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {CartOnlineInPost, CartOnlineOutPost} from "@shared/service/api/cart/online/interface";

@Injectable({providedIn: "root"})
export class CartOnlineService {
    constructor(private _httpClient: HttpClient, private _city: CityService) {}

    /**
     * Онлайн корзина
     *
     * @returns {Observable<CartOnlineInPost>}
     */
    post(_obj: CartOnlineInPost): Observable<CartOnlineOutPost> {
        return this._httpClient.post<CartOnlineOutPost>('ma/site/v1/cart/online', _obj);
    }
}
