import {Inject, Injectable, Injector} from "@angular/core";
import {RedirectInject} from "@core/service/redirect/inject";
import {Redirect} from "@core/service/redirect/interface";
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class RedirectService {
    constructor(
        @Inject(RedirectInject) private _redirect: Redirect,
        @Inject(Injector) public _injector: Injector,
    ) {}

    public _200(url: string, params?: any) {
        if (url) {
            const newUrl = this.url(url, params);
            this._redirect._200(newUrl.url, newUrl.query);
        }
    }

    public async _301(url: string, params?: any) {
        if (url) {
            const newUrl = this.url(url, params);
            return await this._redirect._301(newUrl.url, newUrl.query);
        }
        return Promise.resolve();
    }

    public async _404() {
        await this._redirect._404();
    }

    public main() {
        this._redirect.main();
    }

    private url(url: string, params?: any) {
        const activatedRoute = this._injector.get(ActivatedRoute);
        const queryParams = activatedRoute.snapshot.queryParams;
        const queryParamsTmp = params ? params : queryParams;

        const arrUrlTmp = [];
        let urlNew = "/";
        if (url !== "/") {
            const arrUrl = url.replace(/\?.*/, "").split("/");

            arrUrl.forEach((v) => {
                if (v) {
                    arrUrlTmp.push(v);
                }
            });
            urlNew = "/" + arrUrlTmp.join("/");
        }

        return {
            url: urlNew,
            query: queryParamsTmp,
        };
    }
}
