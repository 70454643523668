import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: '.ma-svg-magnifier',
    templateUrl: '../../../../../assets/icons/svg/search.svg',
    styles: [':host {display: inherit}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagnifierComponent {
}
