import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../../request";
import {InGet, OutGet} from "./interface";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class ItemsStoresService {

    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     * Список доступных аптек
     *
     * @param {InGet} obj
     * @returns Promise<OutGet>
     */
    get(obj: InGet): Promise<OutGet> {
        if (!obj.is_open) {
            obj.is_open = false;
        }
        return this._request
            .query('ma/site/v4/items/stores', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }
}


