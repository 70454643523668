import {Injectable} from "@angular/core";
import {LsService} from "../localstorage";

@Injectable()
export class StoreService {
    public name: string = 'store';

    constructor(private _lsService: LsService) {}

    /**
     * Возращает ид магазин
     *
     * @returns {number}
     */
    get() {
        return +this._lsService.get(this.name);
    }

    /**
     * Сохраняет ид магазина
     *
     * @param {number} id
     * @returns {number}
     */
    set(id: number){
        return +this._lsService.set(this.name, id);
    }
}

