import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../request";
import {InPost} from "./interface";
import {Response} from "@core/service/api2/request";
import {ApiUtilsService} from "@core/service/api2/utils";

@Injectable()
export class PharmService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     *
     * @param {InPost} obj
     * @returns {Promise<any>}
     */
    post(obj?: InPost): Promise<any> {
        return this._request
            .create('ma/site/v1/pharm/request', obj)
            .then((res: Response) => res.json);
    }

}

