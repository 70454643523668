import {Injectable} from "@angular/core";
import {Refresh} from "@core/service/refresh";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {Storage} from "@core/service/city/storage";
import {Cookie} from "@core/service/cookie";
import {City} from "@core/model/city";

@Injectable({
    providedIn: "root",
})
export class CityService extends Storage {
    public data: City;
    private cityNull: City = {
        id: 6565,
        code: "moskva",
        name: "Москва",
        declinations: {name_genitive: "Москвы", name_prepositional: "Москве"},
        timezone: 10800,
        latitude: 55.753215,
        longitude: 37.622504,
        region_id: 6989,
        region_name: "Московская область и Москва",
        delivery_price: 0,
        free_delivery_sum: 0,
        sort: 0,
        is_delivery: false,
        is_payment: false,
        declination: "",
        is_index: true,
        stores_quantity: 0,
    };

    public city$: BehaviorSubject<City> = new BehaviorSubject(this.cityNull);
    /**
     * Статус загрузки города, вызывается только один раз при загрузке приложения в app-initializer.
     */
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        protected _cookie: Cookie,
        private _refresh: Refresh,
    ) {
        super(_cookie);
        this.data = this.cityNull;
    }

    /**
     * Возращает текущий город из хранилище
     *
     */
    getCity(): number {
        return +this.city;
    }

    /**
     * Запись города в хранилище
     *
     */
    setCity(obj: City, _refresh: boolean = true): void {
        if (obj.id) {
            this.city = obj.id.toString();
            this.data = obj;
            this.city$.next(obj);
            this.loading$.next(true);
        }
        //
        // if (_refresh) {
        //     this._refresh.routerOutletRefresh();
        // }
    }

    /**
     * Проверка установки определения координат пользователя
     *
     * @returns {boolean}
     */
    public isGeolocation(): boolean {
        return this.getGeolocation() === "1";
    }

    /**
     * Определения координат пользователя
     *
     * @returns {string}
     */
    public getGeolocation(): string {
        return this.geolocal;
    }

    /**
     * Установки определения координат пользователя
     *
     * @param {boolean} _status
     */
    public setGeolocation(_status: boolean): void {
        this.geolocal = _status ? "1" : "";
    }
}
