import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";

@Component({
    selector: "ma-ui-alphabet",
    standalone: true,
    templateUrl: "./alphabet.component.html",
    styleUrls: ["./alphabet.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
        NgIf,
        RouterLinkActive,
        NgForOf,
    ],
})
export class UiAlphabetComponent {
    @Input() isTitle = true;

    public abc = [
        "а",
        "б",
        "в",
        "г",
        "д",
        "е",
        "ж",
        "з",
        "и",
        "й",
        "к",
        "л",
        "м",
        "н",
        "о",
        "п",
        "р",
        "с",
        "т",
        "у",
        "ф",
        "х",
        "ц",
        "ч",
        "ш",
        "э",
        "ю",
        "я",
        "a-z",
        "0-9",
    ];

}
