<div class="menu-drop down">
    <div class="menu-drop-step1">
        <ng-container *ngFor="let elem of list">
            <ng-template [ngIf]="elem.url">
                <a routerLink="{{elem.url}}" class="menu-drop-step1-elem">{{elem.title}}</a>
            </ng-template>
            <ng-template [ngIf]="elem.onClick">
                <a (click)="elem.onClick()" class="menu-drop-step1-elem">{{elem.title}}</a>
            </ng-template>
        </ng-container>

    </div>
</div>
