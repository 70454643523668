import { Inject, Injectable } from "@angular/core";
import { LogService } from "@core/service/logger";
import { LazyScript } from "@core/service/lazy-script";
import { Config } from "@core/service/config";
import { MetaTagService } from "@core/service/meta-tag";
import { MailSettings } from "@core/service/mail-analytics/interface";
import { DOCUMENT } from "@angular/common";

declare const _tmr: any;

@Injectable()
export class MailAnalyticsService {
    private config: MailSettings;
    private _logger: LogService = new LogService();

    constructor(private _lazyScript: LazyScript,
                private _config: Config,
                @Inject(DOCUMENT) private _document: Document,
                private _metaTagService: MetaTagService) {
        this.config = this._config.get('mail');
    }

    /**
     * Запуск
     *
     * @returns {void}
     */
    public start(): void {
        this._lazyScript
            .load((this._document.location.protocol === 'https:' ? 'https:' : 'http:') + '//top-fwz1.mail.ru/js/code.js', true)
            .then(() => {
                this._metaTagService.title$.subscribe((res) => {
                    if (res.title) {
                        this.transition();
                    }
                });
                return;
            });
    }


    transition() {
        try {
            _tmr.push({
                id: this.config.id,
                type: 'pageView',
                start: (new Date()).getTime(),
                url: this._document.location.pathname
            });
        } catch (e) {
            this._logger.error('MailAnalyticsService', e);
        }
    }
}
