import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {Group} from "./list";


@Component({
    selector: "app-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ListComponent implements OnInit {
    @Input() view: any;
    @Input() inFixed: boolean = false;
    @Input() groups: Group[] = [];

    public activeTab: Group = null;

    ngOnInit() {
        this.activeTab = this.groups[0];
    }

    public step2Up(tab: Group): void {
        this.activeTab = tab;
    }

    public trackByFn(index: number, elem: Group["children"][0]): any {
        return elem.code;
    }
}
