import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: 'ma-svg-close, .ma-svg-close',
    templateUrl: '../../../../../assets/icons/svg/close.svg',
    styles: [':host {display: inherit}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseComponent {
}
