import {Injectable} from "@angular/core";
import {Storage} from "@core/service/favorites/storage";
import {LsService} from "@core/service/localstorage";
import {ApiService} from "@core/service/api2";
import {UserService} from "@core/service/user";
import difference from "lodash-es/difference";
import union from "lodash-es/union";
import {filter, map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {ItemsFavoritesOutGetOld, ItemsFavoritesService} from "@shared/service/api/items/favorites";
import {Observable} from "rxjs";


@Injectable({
    providedIn: "root",
})
export class FavoritesService extends Storage {
    public list$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private _lsService: LsService,
                private _userService: UserService,
                private itemsFavoritesService: ItemsFavoritesService,
                private _api: ApiService) {
        super(_lsService);
    }

    public init() {
        this.getList();

        this._userService.logout$
            .pipe(filter(res => res === true))
            .subscribe(() => {
                this.setList([]);
            });
    }

    private getList(): Promise<number[]> {
        this.loading$.next(true);
        return new Promise((resolve) => {
            this._userService.isAuth$
                .subscribe((res) => {
                    if (res) {
                        this.getApi()
                            .subscribe((resGetApi) => {
                                const list = (resGetApi.items_ids !== null) ? resGetApi.items_ids : [];
                                this.merge(list);
                                this.loading$.next(false);
                                resolve(list);
                            });
                    } else {
                        const list = (this.getStorage() !== null) ? this.getStorage() : [];
                        this.list$.next(list);
                        this.loading$.next(false);
                        resolve(list);
                    }
                });
        });
    }

    private setList(_list: number[]) {
        this.list$.next(_list);
        const authCheck = this._userService.resultCheck.getValue();
        if (authCheck) {
            this.setStorage([]);
        } else {
            this.setStorage(_list);
        }
    }

    /**
     * Проверка товара в избранном
     *
     * @param idItem
     */
    public is(idItem: number): boolean {
        const list = this.list$.getValue();
        return list.indexOf(idItem) !== -1;
    }

    /**
     * Запись в избранное
     *
     * @param idItem
     */
    public set(idItem: number): void {
        if (idItem) {
            const list = this.list$.getValue();
            if (list.indexOf(idItem) === -1) {
                list.push(idItem);
                this.setList(list);
                this.addApi(idItem);
            }
        }
    }

    /**
     * Удаление из избранного
     *
     * @param idItem
     */
    public delete(idItem: number): void {
        if (idItem) {
            const list = this.list$.getValue();
            const key = list.indexOf(idItem);
            if (key !== -1) {
                list.splice(key, 1);
                this.setList(list);
                this.deleteApi(idItem);
            }
        }
    }

    /**
     *
     * @param itemsOfApi
     */
    private merge(itemsOfApi: number[]): void {
        const listStorage = this.getStorage();


        const diffItem1 = difference(listStorage, itemsOfApi);
        const diffItem2 = difference(itemsOfApi, listStorage);
        const diffItem = union(diffItem1, diffItem2);
        const unionItem = union(itemsOfApi, listStorage);

        if (diffItem.length > 0) {
            const add = [];
            listStorage.forEach((v) => {
                add.push({
                    item_id: v, is_deleted: false,
                });
            });
            this.addApi(0, add);
            this.setList(unionItem);
        }

        this.list$.next(unionItem);
    }

    private addApi(id: number = 0, list: {item_id: number, is_deleted: boolean}[] = []) {
        const authCheck = this._userService.resultCheck.getValue();
        if (authCheck) {
            if (id !== 0) {
                this.itemsFavoritesService.put({
                    list: [{item_id: id, is_deleted: false}],
                }).subscribe();
            }
            if (list.length > 0) {
                this.itemsFavoritesService.put({
                    list: list,
                }).subscribe();
            }
        }

    }

    private deleteApi(id: number = 0, list: {item_id: number, is_deleted: boolean}[] = []) {
        const authCheck = this._userService.resultCheck.getValue();
        if (authCheck) {
            if (id !== 0) {
                this.itemsFavoritesService.put({
                    list: [{item_id: id, is_deleted: true}],
                }).subscribe();
            }
            if (list.length > 0) {
                this.itemsFavoritesService.put({
                    list: list,
                }).subscribe();
            }

        }
    }

    private getApi(): Observable<ItemsFavoritesOutGetOld> {
        return this.itemsFavoritesService
            .getOld({})
            .pipe(map((res) => {
                if (res.items_ids !== null && res.items_ids.length > 0) {
                    res.items_ids = res.items_ids.map((i) => {
                        return +i;
                    });
                } else {
                    res.items_ids = [];
                }

                return res;
            }));
    }
}
