import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageLazyComponent} from './image-lazy.component';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule} from 'ng-lazyload-image';
import {ScrollHooks} from 'ng-lazyload-image';

@NgModule({
    declarations: [ImageLazyComponent],
    imports: [
        CommonModule,
        LazyLoadImageModule
    ],
    providers: [{provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}],
    exports: [ImageLazyComponent]
})
export class ImageLazyModule {
}
