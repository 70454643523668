import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: 'app-svg-logo-ma, .ma-svg-logo-ma',
    templateUrl: './logo-ma.component.svg',
    styles: [':host {display: inherit}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoMaComponent {
    @Input() color1 = '#F63F3F';
    @Input() color2 = '#000';
    @Input() width = '231px';
    @Input() height = '25px';
    @Input() opacity = '1';
}
