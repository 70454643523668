import {Injectable} from "@angular/core";
import {PartnersService} from "@core/service/api2/methods/partners";
import {FaqService} from "app/shared/service/api/faq";
import {SymptomsService} from "@core/service/api2/methods/symptoms";
import {ProductsService} from "@shared/service/api/products";
import {StoresSearchStatService} from "@core/service/api2/methods/stores/search/stat";
import {ItemsSubscribeService} from "@core/service/api2/methods/items/subscribe";
import {AuthTelemetryService} from "@core/service/api2/methods/auth/telemetry";
import {AuthCheckService} from "@core/service/api2/methods/auth/check";
import {OrdersBasketService} from "@core/service/api2/methods/orders/basket";
import {ItemsService} from "@core/service/api2/methods/items";
import {OrdersService} from "@core/service/api2/methods/orders";
import {ItemsTypeAnalogsService} from "@core/service/api2/methods/items/type-analogs";
import {ArticlesService} from "@core/service/api2/methods/articles";
import {SpecialsDetailService} from "@core/service/api2/methods/specials/detail";
import {ActmattersDetailService} from "@core/service/api2/methods/actmatters/detail";
import {CitiesService} from "@core/service/api2/methods/cities";
import {CitiesDetailService} from "@core/service/api2/methods/cities/detail";
import {CitiesSimpleService} from "@core/service/api2/methods/cities/simple";
import {ParametersService} from "@core/service/api2/methods/parameters";
import {GroupsService} from "@core/service/api2/methods/groups";
import {OrdersRateService} from "@core/service/api2/methods/orders/rate";
import {RatingService} from "@core/service/api2/methods/rating";
import {PharmService} from "@core/service/api2/methods/pharm";
import {GroupsTagsMedkitsService} from "@core/service/api2/methods/groups/tags/medkits";
import {CommentsService} from "@core/service/api2/methods/comments";
import {CitiesLinkingService} from "@core/service/api2/methods/cities/linking";
import {SendlinkService} from "@core/service/api2/methods/sendlink";

@Injectable()
export class ApiService {
    constructor(
        public partners: PartnersService,
        public faq: FaqService,
        public symptoms: SymptomsService,
        public products: ProductsService,
        public storesSearchStat: StoresSearchStatService,
        public itemsSubscribe: ItemsSubscribeService,
        public authTelemetry: AuthTelemetryService,
        public authCheck: AuthCheckService,
        public ordersBasket: OrdersBasketService,
        public items: ItemsService,
        public orders: OrdersService,
        public itemsTypeAnalogs: ItemsTypeAnalogsService,
        public articles: ArticlesService,
        public specialsDetail: SpecialsDetailService,
        public actmattersDetail: ActmattersDetailService,
        public cities: CitiesService,
        public citiesDetail: CitiesDetailService,
        public citiesSimple: CitiesSimpleService,
        public parameters: ParametersService,
        public groups: GroupsService,
        public ordersRate: OrdersRateService,
        public rating: RatingService,
        public pharmService: PharmService,
        public groupsTagsMedkits: GroupsTagsMedkitsService,
        public comments: CommentsService,
        public citiesLinking: CitiesLinkingService,
        public sendlink: SendlinkService,
    ) {}
}
