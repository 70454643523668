import {Injectable} from "@angular/core";
import {ApiRequest} from "./../../request";
import {Response} from "@core/service/api2/request";
import {OutGet} from "@core/service/api2/methods/parameters/interface";

@Injectable()
export class ParametersService {

    constructor(private _request: ApiRequest) {
    }

    /**
     * Список партнеров
     *
     * @param {InBanners} obj
     * @returns {Promise<Banners>}
     */
    get(): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/parameters')
            .then((res: Response) => res.json);
    }
}

