import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

interface Comp<T> extends Function {
    new(...args: any[]): T;
}

interface UiFloatingBlockBottomRef<T> {
    comp: Comp<T>,
    data: {}
}

@Injectable({
    providedIn: 'root'
})
export class UiFloatingBlockBottomService {
    public uiFooterFloatingRef$: BehaviorSubject<UiFloatingBlockBottomRef<any>[]> = new BehaviorSubject([]);


    public applinkHref: BehaviorSubject<string> = new BehaviorSubject('https://redirect.appmetrica.yandex.com/serve/890937166409058766?source=red_button');

    updateHref(str: string) {
        this.applinkHref.next(str);
    }

    defaultHref() {
        this.applinkHref.next('https://redirect.appmetrica.yandex.com/serve/890937166409058766?source=red_button');
    }

    getRef(): UiFloatingBlockBottomRef<any>[] {
        return this.uiFooterFloatingRef$.getValue();
    }

    setRef<T>(comp: UiFloatingBlockBottomRef<T> | UiFloatingBlockBottomRef<T>[]): void {
        const list = [];
        if (comp instanceof Array) {
            comp.map((v) => {
                list.push(v);
            });
        } else {
            list.push(comp);
        }

        this.uiFooterFloatingRef$.next(list);
    }

    delRef<T>(comp: Comp<T>) {
        const list = this.getRef();
        const listNew: UiFloatingBlockBottomRef<any>[] = list.reduce((a: any[], v) => {
            if (comp !== v.comp) {
                a.push(v);
            }
            return a;
        }, []);
        this.setRef(listNew);
    }

    eventRef(): BehaviorSubject<UiFloatingBlockBottomRef<any>[]> {
        return this.uiFooterFloatingRef$;
    }
}
