import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopupYourCityComponent} from './popup-your-city.component';


@NgModule({
    declarations: [
        PopupYourCityComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PopupYourCityComponent
    ]
})
export class UiPopupYourCityModule {
}
