<a routerLink="/basket">
    <div class="grid">
        <div class="icon">
            <svg-icon class="ma-svg-basket" [src]="'icons/svg/basket.svg'"></svg-icon>
            <div class="count" *ngIf="(count$ | async) !== 0">{{(count$ | async)}}</div>
        </div>
        <div class="name">Корзина</div>
        <div class="price">{{ (sum$ | async) | formatPrice:0 }}</div>
    </div>
</a>
<ma-basket-small-list *ngIf="(sum$ | async) !== 0 && show" [class]="inFixed ? 'inFixed' : ''"></ma-basket-small-list>
