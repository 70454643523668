import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {NgIf} from "@angular/common";
import {LoadingCommonState} from "@state/loading-common.state";

@Component({
    selector: "ma-loading-common",
    standalone: true,
    imports: [NgIf],
    templateUrl: "./common.component.html",
    styleUrls: ["./common.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCommonComponent {
    private loadingCommonState = inject(LoadingCommonState);
    public state = this.loadingCommonState.loadingState;
}
