import {ChangeDetectionStrategy, Component} from "@angular/core";
import {SvgVkModule} from "@shared/module_v2/svg/vk/vk.module";
import {SvgOkModule} from "@shared/module_v2/svg/ok/ok.module";
import {SvgTgModule} from "@shared/module_v2/svg/tg/tg.module";

@Component({
    standalone: true,
    selector: "app-social-networks",
    templateUrl: "./social-networks.component.html",
    styleUrls: ["./social-networks.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SvgVkModule,
        SvgOkModule,
        SvgTgModule,
    ],
})
export class SocialNetworksComponent {

}
