import {Pipe, PipeTransform} from '@angular/core';
import {Config} from '@core/service/config';

@Pipe({
    name: 'domain'
})
export class DomainPipe implements PipeTransform {
    transform(): string {
        const configSite = new Config().get('site');
        return <string>(configSite.domain) ? configSite.domain : '';
    }
}
