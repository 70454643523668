import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatNativeDateModule} from "@angular/material/core";
import {EmailComponent} from "./email/email.component";
import {PhoneComponent} from "./phone/phone.component";
import {InputCodeComponent} from "./code/component";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        NgxMaskDirective,
    ],
    declarations: [
        EmailComponent,
        PhoneComponent,
        InputCodeComponent
    ],
    exports: [
        EmailComponent,
        PhoneComponent,
        InputCodeComponent
    ],
    providers: [provideNgxMask()],
})
export class InputModule {
}
