import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouterLink} from "@angular/router";

@Component({
    standalone: true,
    selector: "app-help",
    templateUrl: "./help.component.html",
    styleUrls: ["./help.component.less"],
    imports: [
        RouterLink,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent {

}
