import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from "@angular/core";
import {NgForOf, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {GroupsFooterOutGet, GroupsFooterService} from "@shared/service/api/groups/footer";
import {LinkCityDirective} from "@shared/directive/link-city.directive";

@Component({
    standalone: true,
    selector: "app-footer-catalog",
    templateUrl: "./catalog.component.html",
    styleUrls: ["../footer.component.less", "catalog.component.less"],
    imports: [
        NgForOf,
        NgIf,
        RouterLink,
        LinkCityDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GroupsFooterService],
})

export class FooterCatalogComponent implements OnInit {
    private groupsFooterService = inject(GroupsFooterService);
    public list = signal<GroupsFooterOutGet["list"]>([]);

    ngOnInit() {
        this.groupsFooterService
            .get()
            .subscribe((data) => {
                this.list.set(data.list);
            });
    }
}
