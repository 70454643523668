import {inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {LinkDefinition} from "@common/core/service/meta/link";

@Injectable({
    providedIn: "root",
})
export class MetaLinkService {
    private readonly DOCUMENT = inject(DOCUMENT);
    private head = this.DOCUMENT.head;

    /**
     * Создания/обновления тега link
     *
     * @param tag<LinkDefinition>
     */
    public set(tag: LinkDefinition) {
        const linkElement = <HTMLLinkElement>this.get(tag) || this.DOCUMENT.head.appendChild(this.DOCUMENT.createElement("link"));

        if (linkElement) {
            Object.keys(tag).forEach((prop: string) => {
                linkElement[prop] = tag[prop];
            });
        }
    }

    /**
     * Удаления тега link из DOM
     *
     * @param tag<LinkDefinition>
     */
    public remove(tag: LinkDefinition): void {
        const linkElement = this.get(tag);

        if (linkElement) {
            this.head.removeChild(linkElement);
        }
    }

    /**
     * Поиск тега link
     *
     * @param tag<LinkDefinition>
     *
     * @return {HTMLLinkElement}
     */
    public get(tag: LinkDefinition): HTMLLinkElement {
        const selector = this._parseSelector(tag);

        return this.head.querySelector(selector);
    }

    /**
     * Парсер
     *
     * @param  tag<LinkDefinition>
     * @return {string}
     */
    private _parseSelector(tag: LinkDefinition): string {
        const attr: string = tag.rel ? "rel" : "hreflang";
        return `link[${attr}="${tag[attr]}"]`;
    }
}
