import {Inject, Injectable} from "@angular/core";
import {LazyScript} from "@core/service/lazy-script";
import {Config} from "@core/service/config";
import {MetaTagService} from "@core/service/meta-tag";
import {DOCUMENT} from "@angular/common";
import {GoogleService} from "@core/module/google";
import {Platform} from "@core/service/platform";
import {filter, take} from "rxjs/operators";

declare const gtag: any;

@Injectable({
    providedIn: "root",
})
export class GoogleAnalyticsService {

    constructor(private _lazyScript: LazyScript,
                private _config: Config,
                private _metaTagService: MetaTagService,
                private _googleService: GoogleService,
                private _platform: Platform,
                @Inject(DOCUMENT) private _document: Document) {
    }

    run() {
        if (this._platform.browser) {
            this._metaTagService.title$.subscribe((res) => {
                if (res.title) {
                    this.transition();
                }
            });
        }
    }

    eventClick() {
        // Вроде как уже не нужно
        // this._googleService.google$.then((res) => {
        //     if (res) {
        //         ga('send', 'event', {
        //             event_category: 'commerce',
        //             event_action: 'add-to-cart',
        //             event_label: 'add',
        //             eventValue: '10'
        //         });
        //     }
        // }).catch((e) => {
        //     this._logger.error('GoogleAnalyticsService', e);
        // });
    }

    downloadClick() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'download',
                    event_action: 'click'
                });
            });
    }


    transition() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', 'page_view', {
                    page_location: decodeURI(document.location.href),
                    page_path: decodeURI(document.location.pathname + document.location.search)
                    /*'event_callback': function() {
                        console.log('%c Megapteka: Google analytics is ready ', 'background: #222; color: #68abc1');
                    }*/
                });
            });
    }

    notAvaliableItem() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('set', {'content_group1': 'Not avaliable'});
            });
    }


    eventAddItemDetailBasket() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'commerce',
                    event_action: 'add-to-cart',
                    event_label: 'detail-item'
                });
            });
    }

    eventAddItemCartBasket() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'commerce',
                    event_action: 'add-to-cart',
                    event_label: 'cart-item'
                });
            });
    }

    eventCartToDetail() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'commerce',
                    event_action: 'transition-to-item-detail'
                });
            });
    }

    eventTransitionToCheckout() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'commerce',
                    event_action: 'transition-to-checkout',
                    event_label: 'checkout'
                });
            });
    }

    eventClickBannersMainRed() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'banners',
                    event_action: 'click',
                    event_label: 'main_red_mp'
                });
            });
    }


    eventOrderAdd(order) {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                const items = [];
                order.items.forEach((v) => {
                    items.push({
                        'id': v.id,
                        'name': v.name,
                        'brand': v.brand_name,
                        'category': v.group_name,
                        'quantity': v.quantity,
                        'price': v.price
                    });
                });
                gtag('event', 'purchase', {
                        'transaction_id': order.id,
                        'value': order.total_amount,
                        'currency': 'RUB',
                        'items': items
                    }
                );
            });
    }


    eventOrderSuccess() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'commerce',
                    event_action: 'order_success'
                });
            });
    }

    eventAppsSend() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'banners',
                    event_action: 'click',
                    event_label: 'success_banner_send'
                });
            });
    }

    eventClickAppStore() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'banners',
                    event_action: 'click',
                    event_label: 'success_banner_appstore'
                });
            });
    }

    eventClickGooglePlay() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'banners',
                    event_action: 'click',
                    event_label: 'success_banner_googleplay'
                });
            });
    }

    eventClickContinueShopping() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'items',
                    event_action: 'click',
                    event_label: '31395_continueShopping'
                });
            });
    }

    eventClickGoToBasket() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'items',
                    event_action: 'click',
                    event_label: '31395_goToCart'
                });
            });
    }

    eventClickLogoMobile() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'mpLangind',
                    event_action: 'click',
                    event_label: 'logo'
                });
            });
    }

    eventClickLogoDocMobile() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'mpDocLanding',
                    event_action: 'click',
                    event_label: 'logo'
                });
            });
    }

    eventClickFloatFormMobile() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'mpLanding',
                    event_action: 'click',
                    event_label: 'fillingFloatForm'
                });
            });
    }

    eventClickFloatFormDocMobile() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'mpDocLanding',
                    event_action: 'click',
                    event_label: 'fillingFloatForm'
                });
            });
    }


    eventClickFormMobile() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'mpLanding',
                    event_action: 'click',
                    event_label: 'fillingForm'
                });
            });
    }

    eventClickFormDocMobile() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', {
                    event_category: 'mpDocLanding',
                    event_action: 'click',
                    event_label: 'fillingForm'
                });
            });
    }

    eventClickVirtualSuggestClick() {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', 'page_view', {
                    page_path: '/virtual/suggest_click'
                });
            });
    }

    sendEvent(_obj: {event_category: string; event_action: string; event_label: string}) {
        this._googleService.downloadScriptStatus
            .pipe(filter(res => res === true))
            .pipe(take(1))
            .subscribe(() => {
                gtag('event', '', _obj);
            });
    }
}
