import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: ".ma-svg-dots",
    templateUrl: "./dots.component.svg",
    styles: [":host {display: inherit}"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotsComponent {
}
